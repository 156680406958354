import React from 'react';
import type { ReactNode } from 'react';
import styled from 'styled-components';
import { NavBar } from 'react/components/NavBar';
import {
  Route,
  Navigate,
  NavLink,
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Outlet,
  useMatches,
  redirect,
} from 'react-router-dom';
import { BloxText, Flex, SrOnly } from '@pixleeturnto/wr4pt';
import { CampaignBrief } from './CampaignBrief';
import { CampaignMessages } from './campaign_messages/CampaignMessages';
import { CampaignLinks } from './campaign_links/CampaignLinks';
import { RailsAuthenticityContextProvider } from 'react/contexts/authenticityToken';
import { useCampaignInfluencer } from 'react/apis/campaign_influencers';
import { CampaignProducts } from './campaign_products/CampaignProducts';
import { CampaignProductsRecap, CampaignProductsRecapHeader } from './campaign_products/CampaignProductsRecap';
import { CampaignInfluencerShippingAddressForm } from './campaign_products/CampaignInfluencerShippingAddressForm';
import { getProductsFromSessionStorage } from './campaign_products/SelectCampaignProducts';
import type { FetchCampaignInfluencerResult } from 'apis/campaign_influencers';
import { CampaignInfluencerMethods, CampaignMethods } from '@pixleeturnto/wr4pt/dist/models';
import { isObjKey } from 'react/types/utils';

export interface CampaignDetailsProps {
  campaignInfluencer: FetchCampaignInfluencerResult;
  baseUrl: string;
  backLink: string;
  railsAuthenticityToken: string;
}

export const CampaignDetails = ({ campaignInfluencer: initialCampaignInfluencer, baseUrl, backLink, railsAuthenticityToken }: CampaignDetailsProps) => {
  const { data: campaignInfluencer } = useCampaignInfluencer({ campaignInfluencerId: initialCampaignInfluencer.id }, { initialData: initialCampaignInfluencer });
  const isLinksTabDisabled = !campaignInfluencer || !CampaignMethods.isLinkTracking(campaignInfluencer.campaign) || !CampaignInfluencerMethods.isAccepted(campaignInfluencer);
  const isProductsDisabled = !campaignInfluencer || !CampaignInfluencerMethods.isAccepted(campaignInfluencer);

  const routes = createRoutesFromElements(
    <Route
      element={<BaseLayout
        railsAuthenticityToken={railsAuthenticityToken}
        campaignInfluencerModel={campaignInfluencer}
        backLink={backLink}
        isLinksTabDisabled={isLinksTabDisabled}
        isProductsDisabled={isProductsDisabled}
      />}
    >
      <Route errorElement={<ErrorElement />}>
        <Route
          index
          element={<Navigate to="/brief" replace />}
        />
        <Route path="brief" element={<>
          <SrOnly aria-live="polite" aria-atomic="true">
            You are now visiting the campaign brief page
          </SrOnly>
          {!!campaignInfluencer && <CampaignBrief campaignInfluencer={campaignInfluencer} railsAuthenticityToken={railsAuthenticityToken} />}
        </>} />
        <Route path="messages" element={<>
          <SrOnly aria-live="polite" aria-atomic="true">
            You are now visiting the campaign messages page
          </SrOnly>
          {!!campaignInfluencer && <CampaignMessages campaignInfluencer={campaignInfluencer} />}
        </>} />
        {!isLinksTabDisabled &&
          <Route path="links" element={<>
            <SrOnly aria-live="polite" aria-atomic="true">
              You are now visiting the campaign links page
            </SrOnly>
            {!!campaignInfluencer && <CampaignLinks campaignLinks={campaignInfluencer.campaign_influencer_links} />}
          </>} />
        }
        {!isProductsDisabled &&
          <Route path="products">
            <Route
              index
              element={<>
                <SrOnly aria-live="polite" aria-atomic="true">
                  You are now visiting the campaign products page
                </SrOnly>
                {!!campaignInfluencer && <CampaignProducts campaignInfluencer={campaignInfluencer} />}
              </>}
            />
            <Route
              path="recap"
              handle={{ customHeader: !!campaignInfluencer && <CampaignProductsRecapHeader /> }}
            >
              <Route
                index
                element={<>
                  <SrOnly aria-live="polite" aria-atomic="true">
                    You are now visiting the campaign products recap page
                  </SrOnly>
                  {!!campaignInfluencer && <CampaignProductsRecap campaignInfluencer={campaignInfluencer} railsAuthenticityToken={railsAuthenticityToken} />}
                </>}
                loader={() => {
                  // No products to recap, go back to the product selection page
                  const productsInSessionStorage = getProductsFromSessionStorage(campaignInfluencer.id);
                  if (Object.keys(productsInSessionStorage).length === 0) {
                    return redirect('..');
                  }

                  return null;
                }}
              />
              <Route
                path="shipping-address"
                element={<>
                  <SrOnly aria-live="polite" aria-atomic="true">
                    You are now visiting the product shipping address page
                  </SrOnly>
                  {!!campaignInfluencer && <CampaignInfluencerShippingAddressForm campaignInfluencer={campaignInfluencer} authenticityToken={railsAuthenticityToken} />}
                </>}
              />
            </Route>
          </Route>
        }
      </Route>
    </Route>
  );

  const router = createBrowserRouter(routes, { basename: baseUrl });

  return (
    <RouterProvider router={router} />
  );
};

interface BaseLayoutProps {
  railsAuthenticityToken: string;
  campaignInfluencerModel?: FetchCampaignInfluencerResult;
  backLink: string;
  isLinksTabDisabled: boolean;
  isProductsDisabled: boolean;
}

/**
 *  Look for a property in an unknown object (type safe)
 */
function getPropFromUnknown(obj: unknown, prop: string) {
  return obj && typeof obj === 'object' && isObjKey(prop, obj) && obj[prop] ? obj[prop] : undefined;
}

const BaseLayout = ({ railsAuthenticityToken, campaignInfluencerModel, backLink, isLinksTabDisabled, isProductsDisabled }: BaseLayoutProps) => {
  const matches = useMatches();

  // Check if any child defines a custom header
  const customHeader = matches.reduce<ReactNode | undefined>((customHeader, match) => getPropFromUnknown(match.handle, 'customHeader') ?? customHeader as ReactNode | undefined, undefined);

  const campaignName = campaignInfluencerModel ? CampaignMethods.getName(campaignInfluencerModel.campaign) ?? '' : '';
  return (
    <RailsAuthenticityContextProvider token={railsAuthenticityToken}>
      {customHeader ?? <NavBar title={campaignName} backLink={backLink} />}

      <Container className="page">
        <Content>
          <Outlet />
        </Content>
        <NavFooter as="nav">
          <StyledNavLink to='brief'>
            <NavIcon className="fa-light fa-file-signature" />
            Brief
          </StyledNavLink>
          {campaignInfluencerModel?.campaign?.has_product_for_post && (
            <StyledNavLink to='products' className={isProductsDisabled ? 'disabled' : ''}>
              <NavIcon className="fa-light fa-shop" />
              Products
            </StyledNavLink>
          )}
          {campaignInfluencerModel && CampaignMethods.isLinkTracking(campaignInfluencerModel.campaign) &&
            <StyledNavLink to='links' className={isLinksTabDisabled ? 'disabled' : ''}>
              <NavIcon className="fa-light fa-link" />
              Links
            </StyledNavLink>
          }
          <StyledNavLink to='messages'>
            <NavIcon className="fa-light fa-comments" />
            Messages
          </StyledNavLink>
        </NavFooter>
      </Container>
    </RailsAuthenticityContextProvider >
  );
};

const ErrorElement = () => {
  return <BloxText $mt={5} $alignSelf="center" role="alert">An unexpected error occurred.</BloxText>;
};


const Container = styled.div`
  background: var(--page-background);
  max-width: initial;
  padding: var(--header-offset) 0 var(--nav-footer-width);
  display: flex;
  flex-direction: column;
  min-height: 100%;
`;

const NavFooter = styled(Flex).attrs({
  $justify: 'space-around',
  $align: 'center',
  $h: 90,
  $w: '100%',
  $mw: 'var(--page_max_width)',
})`
  position: fixed;
  bottom: 0;
  background-color: white;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;

const StyledNavLink = styled(NavLink)`
  color: var(--blox-slate5);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-family: var(--blox-font-family);
  font-size: 12px;
  line-height: var(--blox-line-height);
  width: var(--nav-footer-width);
  height: 70px;

  &.active {
    color: var(--blox-purple);
    font-weight: 700;
  }

  &.disabled {
    pointer-events: none !important;
    color: var(--blox-slate1a);
  }
`;

const NavIcon = styled.i`
  font-size: 26px;
`;

const Content = styled.div`
  background: white;
  border-radius: 10px;
  flex: 1;
  max-width: var(--page_max_width);
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;
