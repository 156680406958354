/*
 * Handles changing the settings of a connected_user via aJAX
 */
import { Controller } from 'stimulus';
import { getMetaValue } from 'controllers/helpers/stimulus_helpers';

export default class SettingsController extends Controller {
  static targets = ['pixleeMeNotifications', 'option'];

  // perform AJAX call on settings toggle
  async toggle(event) {
    const isOk = await this.#updateNotifications();
    if (isOk) {
      Toastr.success('Updates have been saved.');
    } else {
      Toastr.error('An error has occurred, please try again.');
      // Toggle it back to the previous value since it didn't work
      event.target.checked = !event.target.checked;
    }

    if (event.target.checked) {
      this.optionTarget.classList.add('blue_border');
    } else {
      this.optionTarget.classList.remove('blue_border');
    }
  }

  #updateNotifications = async () => {
    const formData = new FormData();
    formData.append('creator_profile[receives_notifications]', this.pixleeMeNotificationsTarget.checked);
    const csrfToken = getMetaValue('csrf-token');
    const url = this.data.get('update-url');
    try {
      const response = await fetch(url, {
        body: formData,
        method: 'PATCH',
        dataType: 'script',
        credentials: 'include',
        headers: {
          'X-CSRF-Token': csrfToken,
        },
      });
      return response.ok;
    } catch {
      // A network error has occurred. This is not the same as HTTP errors such as 404.
      return false;
    }
  };
}
