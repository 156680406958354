/*
 * Helper and formatting functions for the campaign chat messages
 */
import { Controller } from 'stimulus';
import linkifyStr from 'linkify-string';

// Controller to localize time in views
export default class CampaignChatMessageController extends Controller {
  static targets = ['body'];

  connect = () => {
    this.linkifyChatBody();
  };

  // add the corresponding href in the message body for linking urls
  linkifyChatBody = () => {
    if (this.hasBodyTarget) { // It doesn't exist for attachment messages
      this.bodyTarget.innerHTML = linkifyStr(this.bodyTarget.innerHTML, {
        className: 'linkified',
        target: (href, type) => type === 'url' && '_blank',
      });
    }
  };
}
