import React from 'react';
import styled from 'styled-components';
import type { BloxSelectOption } from '@pixleeturnto/wr4pt';
import { BloxInput, BloxLabel, BloxSelect, BloxText, Flex, Box, ProductImage } from '@pixleeturnto/wr4pt';
import type { ProductAsJson } from 'react/types/product';
import { ProductMethods } from '@pixleeturnto/wr4pt/models';


export interface ProductProps {
  product: ProductAsJson;
  selected: boolean;
  selectedVariantId?: string;
  onSelectedToggle?: (productId: number, variantId: string | null, selected: boolean) => void;
  disabled?: boolean;
}

/**
 * One product in the list of products to select. It allows to select a variant.
 */
export const ProductDetails = ({ product, selected, selectedVariantId, onSelectedToggle, disabled }: ProductProps) => {
  const allAvailableOptions: Record<string, string[]> = ProductMethods.getAvailableOptions(product);
  const preSelectedOptions = selectedVariantId ? ProductMethods.getVariantOptions(product.variants_json?.[selectedVariantId]) ?? {} : {};

  const [selectedOptions, setSelectedOptions] = React.useState<Record<string, unknown>>(preSelectedOptions);
  const [productOptions, setProductOptions] = React.useState<Record<string, BloxSelectOption[]>>(
    Object.entries(allAvailableOptions).reduce((result, [optionName, optionValue]) => ({
      ...result,
      [optionName]: optionValue.map(value => ({ label: value, value }))
    }), {})
  );

  // Resetting selected options when product is unselected
  const [previousSelected, setPreviousSelected] = React.useState(selected);
  if (previousSelected !== selected) {
    setPreviousSelected(selected);
    if (!selected) {
      setSelectedOptions({});
    }
  }

  const handleSelection = (e: CustomEvent<BloxSelectOption>, availableOptionName: string) => {
    // Not all options combination correspond to an actual variant.
    // For example, given 2 options: Color: [Red, Blue] and Size: [S, M], and 2 variants: [Red/S, Blue/M], we see that Red/M and Blue/S are not possible.
    // So the logic is as follows:
    // 1. User selects one of the options
    // 2. We update the other dropdowns to disable the options not compatible with the current selection
    // 3. When all options have a value (all dropdown filled), all options become enabled again.
    //    Otherwise, in the example above, after selecting Red/S, the user could never select Blue/M again.


    // The value of the option we just selected
    const selectedOptionValue = e.detail.value;

    // The option we just selected, in th format { name: value }
    const optionToSelect = { [availableOptionName]: selectedOptionValue };

    // The new selection, with the option we just selected
    let newOptions = { ...selectedOptions, [availableOptionName]: selectedOptionValue };

    // Did we pick something for all options yet or are some blox-select still empty?
    let allOptionsAreSelected = Object.keys(allAvailableOptions).every(optionName => !!newOptions[optionName]);

    // If all blox-select are filled, we need to "fix" the selection to make sure we're selecting a valid variant.
    // The blox-selects with incompatible values will be reseted
    if (allOptionsAreSelected) {
      newOptions = fixSelection(selectedOptions, optionToSelect);
      // After the fix above, are all options still selected or did some blox-select get resetted?
      allOptionsAreSelected = Object.keys(allAvailableOptions).every(optionName => !!newOptions[optionName]);
    }

    // Now we will compute the blox-select options and disable the options that need to be disabled
    const productOptions: Record<string, BloxSelectOption[]> = Object.entries(allAvailableOptions).reduce((result, [optionName, optionValue]) => ({
      ...result,
      [optionName]: optionValue.map(val => {
        // When all options are selected, we enable all options to not "block" the user
        // Disabled options are only shown as a hint when selecting an option that hasn't been selected yet
        const optionDisabled = !allOptionsAreSelected && !ProductMethods.isOptionCombinationPossible(product, { ...newOptions, [optionName]: val });

        return { // This is what get passed to blox-select
          label: `${val}${optionDisabled ? ' (not available)' : ''}`,
          value: val,
          disabled: optionDisabled
        };
      })
    }), {});

    // Update the state
    setSelectedOptions(newOptions);
    setProductOptions(productOptions);

    // Also inform the parent that we've interacted with a product so that it gets checked
    // We don't necesarily have a variant yet if some options are still not selected
    onSelectedToggle?.(product.id, allOptionsAreSelected ? ProductMethods.getVariantId(product, newOptions) ?? null : null, true);
  };

  /**
   * Given an option that was just selected (optionToSelect), this will 
   * remove the incompatible values from `selectedOptions` and return the result
   * @param {*} selectedOptions  - The currently selected options that need to be "fixed"
   * @param {*} optionToSelect  - The option that was just selected
   */
  const fixSelection = (selectedOptions: object, optionToSelect: Record<string, string>) => {
    // We start with the option we just selected
    const result = { ...optionToSelect };

    // Then for each option in the current selection, we check if it's compatible. We only add it to the result
    // if compatible.

    Object.entries(selectedOptions).forEach(([optionName, optionValue]) => {
      if (optionName in result) { // In case optionToSelect is a new value for an existing option.
        return;
      }

      // If the option is compatible, we add it to the result
      if (ProductMethods.isOptionCombinationPossible(product, { ...result, [optionName]: optionValue })) {
        result[optionName] = optionValue;
      }
    });

    return result;
  };

  const handleSelectedChange = () => {
    onSelectedToggle?.(product.id, null, !selected);
  };

  const currentPhotoUrl = ProductMethods.getVariantPhotoUrl(product, selectedOptions);

  return (
    <Container onClick={handleSelectedChange} selected={selected}>
      {/* Rendering the variant photo OR the main product photo if not found */}
      <ProductImage $w="108px" photoUrl={currentPhotoUrl ?? ''} fallbackPhotoUrl={ProductMethods.getPhotoUrl(product) ?? undefined} productName={ProductMethods.getName(product) ?? ''} />
      <Flex $f={1} $fd="column">
        <BloxLabel onClick={e => e.stopPropagation()} $d="block" className="font-md-label" htmlFor={`product-${product.id}`}>{ProductMethods.getName(product)}</BloxLabel>
        <BloxText className="font-md-label" $cname="slate3" $mt={5}>{ProductMethods.getVariantFormattedPrice(product, selectedOptions)}</BloxText>

        <Box $my="auto" onClick={e => e.stopPropagation()}>
          {Object.keys(productOptions).map(optionName => {
            const selectOptions = productOptions[optionName];
            const selectedValue = selectedOptions[optionName];
            return <BloxSelect
              key={optionName}
              options={JSON.stringify(selectOptions)}
              selectedValue={typeof selectedValue === 'string' ? selectedValue : undefined}
              label=""
              $mt={5}
              $d="block"
              disabled={disabled}
              onSelection={(e) => handleSelection(e, optionName)}
            />;
          })}
        </Box>
      </Flex>
      <SelectionCheckbox
        aria-label="Select product"
        disabled={disabled}
        $align="center"
        id={`product-${product.id}`}
        checked={selected}
        onChange={handleSelectedChange}
      />
    </Container>
  );
};

const Container = styled.div<{ selected: boolean; }>`
  border-radius: 5px;
  border: 1px solid ${props => props.selected ? 'var(--blox-blue)' : 'var(--blox-slate1c)'};
  background: var(--blox-slate0a);
  padding: 10px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 15px;
  position: relative;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
`;

const SelectionCheckbox = styled(BloxInput).attrs({ type: 'checkbox' })`
    width: 30px !important;
    height: 30px !important;
    border-radius: 50% !important;
    border: 2px solid var(--blox-slate1b) !important;
    position: absolute;
    top: 10px;
    left: 10px;

    &:checked {
      border: 2px solid var(--blox-blue) !important;
    }
`;
