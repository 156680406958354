import { useEvent, BloxAnchor, BloxText } from '@pixleeturnto/wr4pt';
import { CampaignMessageMethods } from '@pixleeturnto/wr4pt/dist/models';
import moment from 'moment';
import React from 'react';
import styled from 'styled-components';
import { Loader } from '../../Loader';
import type { TransformedSingleMessage } from 'react/apis/campaign_messages';

export interface MessagesListProps {
  allData?: TransformedSingleMessage[];
  pagesCount?: number;
  hasNextPage?: boolean;
  isFetchingNextPage: boolean;
  fetchNextPage: () => void;
  isLoading: boolean;
  onFileClick: () => void;
}

/**
 * The actual list of messages + the send message form
 */
export const MessagesList = ({ allData, pagesCount, hasNextPage, isFetchingNextPage, fetchNextPage, isLoading, onFileClick }: MessagesListProps) => {
  const scrollRef = React.useRef<HTMLDivElement>(null);
  const scrollData = React.useRef<{ top: number, height: number; }>();


  const firstMessageId = allData?.[0]?.id;
  const lastMessageId = allData?.[allData.length - 1]?.id;


  //  When loading the screen or when a new message is received, scroll to the bottom.
  //  We need useLayoutEffect because we need it done asap so that the user doesn't notice.
  React.useLayoutEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [lastMessageId]); // When lastMessageId changes, we added some items to the bottom


  // When scrolling up and new messages are loaded (page count has changed), we restore scroll position so
  // that the user stays at the same place
  React.useLayoutEffect(() => {
    if (scrollData?.current && scrollRef.current) {
      // Some items were added to the top: restore scroll position
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight - scrollData.current.height;
    }
  }, [pagesCount]);

  // When we start fetching the next page, we save the scroll position so that we can restore it when the
  // new page is added at the top
  React.useEffect(() => {
    if (isFetchingNextPage && scrollRef.current) {
      // Saving current scroll position + fetching new data
      scrollData.current = {
        top: scrollRef.current.scrollTop,
        height: scrollRef.current.scrollHeight,
      };
    }
  }, [isFetchingNextPage]);

  // When we reach the top, if there are more pages, fetch the next page
  const handleIntersection = useEvent(() => {
    if (hasNextPage) {
      fetchNextPage();
    }
  });

  // If we have more pages, 
  // we set up the scroll observer to be able to fetch the next page when reaching the top
  React.useEffect(() => {
    if (scrollRef.current && hasNextPage) {
      // Tracking when the first item comes into viewport
      const firstMessageContainer = scrollRef.current.querySelector(`[data-message-id="${firstMessageId}"]`);
      if (!firstMessageContainer) {
        return;
      }

      const observer = new IntersectionObserver((entries) => {
        if (entries.some(e => e.isIntersecting)) {
          handleIntersection();
        }
      }, {
        root: scrollRef.current,
      });

      observer.observe(firstMessageContainer);

      return () => {
        observer.unobserve(firstMessageContainer);
      };
    }
  }, [firstMessageId, hasNextPage, handleIntersection]);

  return (
    <InfiniteScrollContainer
      ref={scrollRef}
    >

      {(isFetchingNextPage || isLoading) && <Loader>Loading ...</Loader>}
      {
        allData?.map((m) => (
          <Message key={m.id} className={`${CampaignMessageMethods.isFromMe(m) ? 'from-me' : ''} ${m.$$uiPending ? 'loading' : ''}`} data-message-id={m.id}>
            <BloxText className="font-sm-normal" $c="var(--blox-slate2)" $mb={15}>{moment.utc(m.created_at).local().calendar()}</BloxText>

            <MessageContent>
              {m.body}
              {m.attachments?.map(a => (
                <Attachment key={a.id}>
                  <FileLink href={a.url} onClick={onFileClick} target="_blank">{a.filename}</FileLink>

                  {a.human_size ?
                    <>
                      &nbsp;({a.human_size})
                    </> : ''}

                </Attachment>
              ))}
            </MessageContent>

          </Message>)
        )}
    </InfiniteScrollContainer>
  );
};


const Message = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;

  &.from-me {
    text-align: right;
    align-items: flex-end;
  }

  &.loading {
    opacity: 0.5;
  }
`;

const MessageContent = styled.div`
  max-width: 75%;
  overflow: hidden;
  color: var(--blox-slate7);
  background: var(--blox-slate1);
  padding: 10px 20px;
  border-radius: 25px;
  font-size: 14px;
  font-family: var(--blox-font-family);
  margin-bottom: 10px;
  width: fit-content;
  white-space: pre-line;

  ${Message}.from-me & {
    background: var(--blox-purple--active);
    color: white;
  }
`;

const Attachment = styled.div`
  display: flex;
  align-items: baseline;
  white-space: nowrap;
  gap: 5px;
  font-size: 12px;
  font-weight: 400;
`;

const FileLink = styled(BloxAnchor)`
  color: inherit !important;
  font-weight: 700 !important;
  font-size: inherit !important;
  flex: 1;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const InfiniteScrollContainer = styled.div`
  padding: 10px;
  overflow-y: auto;
  flex: 1;
`;
