import { pttFetch } from '@pixleeturnto/wr4pt';
import type { ShippingAddress } from './account';

export interface SaveCreateProductsParams {
  campaignInfluencerId: number;
  products: {
    product_id: number;
    product_variant_id?: string;
  }[];
  shippingAddress: ShippingAddress;
  authenticityToken: string;
}

export type SaveCreateProductsResult = void;

export const saveCreatorProducts = async ({
  campaignInfluencerId,
  products,
  authenticityToken,
  shippingAddress,
}: SaveCreateProductsParams) => {
  const { data } = await pttFetch<SaveCreateProductsResult>(`/campaign_influencer_products/select_products?campaign_influencer_id=${campaignInfluencerId}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-CSRF-Token': authenticityToken,
    },
    body: JSON.stringify({ products, shipping_address: shippingAddress }),
  });

  return data;
};

export interface CampaignInfluencerProduct {
  product_id: number;
  product_name: string;
  product_photo?: string;
  variant_id?: string;
  variant_options?: Record<string, unknown>;
  variant_price?: number;
  variant_image_src?: string;
  variant_title?: string;
}

export interface FetchCampaignInfluencerProductsResult {
  selected_products: CampaignInfluencerProduct[];
  brand_order_submitted: boolean;
  shipping_address?: ShippingAddress;
}

export interface FetchCampaignInfluencerProductsParams {
  campaignInfluencerId: number;
}

export const fetchCampaignInfluencerProducts = async ({ campaignInfluencerId }: FetchCampaignInfluencerProductsParams) => pttFetch<FetchCampaignInfluencerProductsResult>(`/campaign_influencer_products?campaign_influencer_id=${campaignInfluencerId}`);
