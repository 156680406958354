import { BloxText, ProductImage, BloxButton } from '@pixleeturnto/wr4pt';
import type { CampaignInfluencerProduct } from 'apis/campaign_influencer_products';
import React from 'react';
import styled from 'styled-components';

/**
 * The list of selected products. This is shown both when confirming the products selection (CampaignProductsRecap) 
 * and after the products have been submitted (CampaignProductsStatus)
 */
export const SelectedProductsList = ({ selectedProducts, showEdit = false, onEdit }: { selectedProducts: CampaignInfluencerProduct[]; showEdit?: boolean; onEdit?: () => void; }) => {
  return (
    <>
      <BloxText $my={20} $cname="slate4" $fs_md>You have selected the following products...</BloxText>
      {showEdit &&
        <BloxButton className="text" onClick={onEdit} $mb={25}>
          <i className="fa-sharp fa-light fa-arrow-left" />
          Go back and edit your product selections
        </BloxButton>
      }

      {selectedProducts.map((p) => (
        <ProductContainer key={p.product_id}>
          <ProductImageStyled photoUrl={p?.variant_image_src ?? ''} fallbackPhotoUrl={p.product_photo ?? undefined} productName={p.product_name ?? ''} />
          <ProductName>{p.product_name}</ProductName>
          <ProductVariants>{Object.values(p.variant_options ?? {}).join('/')}</ProductVariants>
        </ProductContainer>
      ))}
    </>

  );
};


const ProductContainer = styled.div`
  display: grid;
  grid-template-areas:
  "img name"
  "img variant";
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 5px;
  border: 1px solid var(--blox-slate1c);
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 20px;
`;

const ProductImageStyled = styled(ProductImage)`
  grid-area: img;
  width: 70px;
`;

const ProductName = styled(BloxText)`
  grid-area: name;
  color: var(--blox-slate5);
  font-size: var(--blox-font-size-md);
`;

const ProductVariants = styled(BloxText)`
  grid-area: variant;
  color: var(--blox-slate3);
  font-size: var(--blox-font-size-md);
`;
