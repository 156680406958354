import React from 'react';

const RailsAuthenticityTokenContext = React.createContext('');

export const RailsAuthenticityContextProvider = ({ children, token }) => (
  <RailsAuthenticityTokenContext.Provider value={token}>
    {children}
  </RailsAuthenticityTokenContext.Provider>
);

export const useRailsAuthenticityToken = () => React.useContext(RailsAuthenticityTokenContext);
