import React from 'react';
import { BloxText, Box } from '@pixleeturnto/wr4pt';
import { SelectedProductsList } from './SelectedProductsList';
import type { ShippingAddress } from 'apis/account';
import styled from 'styled-components';
import { CampaignInfluencerShippingAddress } from './CampaignInfluencerShippingAddress';

import type { CampaignInfluencerProduct } from 'apis/campaign_influencer_products';
import type { FetchCampaignInfluencerResult } from 'apis/campaign_influencers';

export interface CampaignProductsStatusProps {
  selectedProducts: CampaignInfluencerProduct[];
  orderSubmitted: boolean;
  campaignInfluencer: FetchCampaignInfluencerResult;
  shippingAddress: ShippingAddress;
  onEditProducts: () => void;
}


/**
 * After products have been selected, this screen displays a recap of the selected products & order status
 */
export const CampaignProductsStatus = ({ selectedProducts, orderSubmitted, campaignInfluencer, shippingAddress, onEditProducts }: CampaignProductsStatusProps) => {
  const brandName = campaignInfluencer.campaign.account?.business_name;
  const shop = campaignInfluencer.campaign.shop_type;

  return <Box $p={20}>
    <StepsGrid>
      <StepIcon $active />
      <BloxText>Products submitted, thanks!</BloxText>
      {!orderSubmitted && <StepInfoText>Please allow {brandName} to review and submit your order.</StepInfoText>}

      <StepIcon $active={orderSubmitted} />
      <BloxText $cname={orderSubmitted ? 'green' : 'slate1b'}>Order submitted</BloxText>
      {orderSubmitted &&
        <StepInfoText>Great news! {brandName} has placed your order through {shop}. Check your inbox for a tracking email.</StepInfoText>
      }
    </StepsGrid>
    <Box $mt={20}>
      <CampaignInfluencerShippingAddress shippingAddress={shippingAddress} />
    </Box>
    <SelectedProductsList selectedProducts={selectedProducts} showEdit={!orderSubmitted} onEdit={onEditProducts} />
  </Box>;
};

const StepsGrid = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 10px;
  align-items: center;
  color: var(--blox-green);
`;

const StepInfoText = styled(BloxText)`
  color: var(--blox-slate3);
  font-size: var(--blox-font-size-sm);
  grid-column: 2;
`;

const StepIcon = styled.i.attrs<{ $active: boolean; }>(props => ({
  className: `fa-regular ${props.$active ? 'fa-circle-check' : 'fa-circle'}`,
})) <{ $active: boolean; }>`
  color: ${({ $active }) => $active ? 'var(--blox-green)' : 'var(--blox-slate1b)'};
`;
