import { Box, BloxText, BloxButton, useStatus } from '@pixleeturnto/wr4pt';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useCampaignProducts } from 'react/apis/campaign_products';
import { Loader } from 'react/components/Loader';
import styled from 'styled-components';
import { getSelectedProductsKey, useSaveCreatorProducts } from 'react/apis/campaign_influencer_products';
import { useShippingAddress } from 'react/apis/account';
import { useQueryClient } from 'react-query';
import type { CampaignInfluencerProduct, FetchCampaignInfluencerProductsResult } from 'apis/campaign_influencer_products';
import { SelectedProductsList } from './SelectedProductsList';
import { getProductsFromSessionStorage, setProductsInSessionStorage } from './SelectCampaignProducts';
import { getCampaignInfluencersQueryKey } from 'react/apis/campaign_influencers';
import { CampaignInfluencerShippingAddress } from './CampaignInfluencerShippingAddress';
import { getShippingAddressFromSessionStorage } from './CampaignInfluencerShippingAddressForm';
import type { FetchCampaignInfluencerResult } from 'apis/campaign_influencers';
import { ProductMethods } from '@pixleeturnto/wr4pt/models';

/**
 * Right after the creator has selected products, this screen displays a confirmation step before submitting. It shows the selected products & shipping address & a submit button.
 */
export const CampaignProductsRecap = ({ campaignInfluencer, railsAuthenticityToken }: { campaignInfluencer: FetchCampaignInfluencerResult; railsAuthenticityToken: string; }) => {
  // A map: selectedProductsId => selectedVariantId. This is sent to us by the previous screen (SelectCampaignProducts).
  const selectedProductsIds = getProductsFromSessionStorage(campaignInfluencer.id);

  const navigate = useNavigate();

  // Fetch the campaign products to get all the details (name, photo, variants, etc.)
  const {
    data: products,
    isLoading: isLoadingProducts,
    isError: isProductsError
  } = useCampaignProducts({ campaignId: campaignInfluencer.campaign?.id, enabled: !!selectedProductsIds });

  // Fetch the shipping address to display it to the creator
  const {
    data: shippingAddress,
    isError: isShippingAddressError,
    isLoading: isLoadingShippingAddress,
  } = useShippingAddress({ enabled: !!selectedProductsIds });
  const savedShippingAddress = getShippingAddressFromSessionStorage(campaignInfluencer.id) || shippingAddress;

  const {
    mutate: saveCreatorProducts,
    isLoading: isSaving,
  } = useSaveCreatorProducts();

  const { showError } = useStatus();
  const queryClient = useQueryClient();

  const getSelectedCampaignInfluencerProducts = (productId: string | number, variantId?: string) => {
    const product = products?.find(product => product.id === Number(productId));
    const variant = product && variantId ? ProductMethods.getVariant(product, variantId) : null;


    return {
      product_id: Number(productId),
      product_name: product ? ProductMethods.getName(product) ?? '' : '',
      product_photo: product ? ProductMethods.getPhotoUrl(product) : '',
      variant_id: variantId,
      variant_options: variant?.variant_options,
      variant_price: variant?.variant_price,
      variant_image_src: variant?.variant_image_src,
      variant_title: variant?.variant_title,
    };
  };

  const selectedProducts: CampaignInfluencerProduct[] = Object.entries(selectedProductsIds).map(([productId, variantId]) => getSelectedCampaignInfluencerProducts(productId, variantId));

  const handleSubmit = () => {
    if (!savedShippingAddress) {
      return;
    }

    saveCreatorProducts({
      authenticityToken: railsAuthenticityToken,
      campaignInfluencerId: campaignInfluencer.id,
      shippingAddress: savedShippingAddress,
      products: selectedProducts.map(p => ({
        product_id: Number(p.product_id),
        product_variant_id: p.variant_id
      }))
    }, {
      onSuccess: (_result, params) => {
        setProductsInSessionStorage(campaignInfluencer.id, {});

        // Update the selected products in cache, so that when we redirect, they are immediatelly displayed
        queryClient.setQueryData<FetchCampaignInfluencerProductsResult>(getSelectedProductsKey(campaignInfluencer.id), () => ({
          brand_order_submitted: false,
          selected_products: params.products.map(({ product_id, product_variant_id }) => getSelectedCampaignInfluencerProducts(product_id, product_variant_id)),
          shipping_address: params.shippingAddress,
        }));

        // Also refetch the influencer data so that we hide the "select products" button on the campaign brief page
        queryClient.invalidateQueries(getCampaignInfluencersQueryKey(campaignInfluencer.id));

        // Redirect to the main product page. Since we have updated the cache, the selected products will be displayed immediately
        navigate('..');
      },
      onError: () => {
        showError('Error saving products, please try again later.');
      }
    });
  };

  if (isLoadingProducts || isLoadingShippingAddress) {
    return <Loader $mt={20}>Loading...</Loader>;
  }

  if (isProductsError || isShippingAddressError) {
    return <BloxText $mt={20} $fs_md $cname="slate7" $textAlign="center">Something went wrong, please try again later.</BloxText>;
  }

  return <Box $p={20}>
    {savedShippingAddress ? (
      <>
        <CampaignInfluencerShippingAddress shippingAddress={savedShippingAddress} />
        <EditAddressLink to="./shipping-address" state={{ shippingAddress: savedShippingAddress }}>Edit address</EditAddressLink>
      </>
    ) : (
      <AddAddressLink to="./shipping-address">Add address</AddAddressLink>
    )}
    <SelectedProductsList selectedProducts={selectedProducts} />
    {
      !!savedShippingAddress && (
        <BloxButton $w="100%" onClick={handleSubmit} disabled={isSaving}>Submit</BloxButton>
      )
    }
  </Box>;
};

export const CampaignProductsRecapHeader = () => (
  <Header>
    <BackLink to=".." relative="path" aria-label="Go back" >
      <i className="fa-sharp fa-light fa-arrow-left"></i>
    </BackLink>
    <BloxText className="font-lg-heading">Back</BloxText>
  </Header>
);

const Header = styled.div`
  border-bottom: 1px solid var(--blox-slate1);
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 19px 20px 19px 29px;;
  position: fixed;
  width: 100%;
  height: var(--header-offset);
  background: white;
`;


const BackLink = styled(Link)`
  color: var(--blox-slate5);
  font-size: 22px;

  &:hover {
    color: var(--blox-slate6);
  }
`;

const AddAddressLink = styled(Link).attrs({
  className: 'blox',
  role: 'button'
})`
  width: 100% !important; // <a class="blox"> has width: auto that we need to override
`;

const EditAddressLink = styled(Link).attrs({
  className: 'blox secondary grey',
  role: 'button'
})`
  width: 100% !important; // <a class="blox"> has width: auto that we need to override
  margin-top: 20px;
`;
