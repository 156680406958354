import { Controller } from 'stimulus';

class RegisterController extends Controller {
  static targets = [
    'submitButton',
    'tosCheckbox',
    'receivesNotificationsCheckbox',
  ];

  #tosChecked = () => this.tosCheckboxTarget.checked;

  #receiveNotificationsChecked = () => this.receivesNotificationsCheckboxTarget.checked;

  #formValid = () => this.#tosChecked() && this.#receiveNotificationsChecked();

  #updateRegisterDisabled = () => {
    this.submitButtonTarget.disabled = !this.#formValid();
  };

  connect = () => this.#updateRegisterDisabled();

  /**
   * This is called whenever the Agree to ToS checkbox is clicked on the goal page.
   */
  onTosChanged = () => this.#updateRegisterDisabled();

  /**
   * This is called whenever the user clicks the checkbox to agree to receive notifications.
   */
  onReceivesNotificationsChanged = () => this.#updateRegisterDisabled();
}

export default RegisterController;
