import React, { useState } from 'react';
import { BloxText } from '@pixleeturnto/wr4pt';
import { Loader } from 'react/components/Loader';
import { useSelectedProducts } from 'react/apis/campaign_influencer_products';
import { CampaignProductsStatus } from './CampaignProductsStatus';
import { SelectCampaignProducts, setProductsInSessionStorage } from './SelectCampaignProducts';
import { setShippingAddressInSessionStorage } from './CampaignInfluencerShippingAddressForm';

import type { FetchCampaignInfluencerResult } from 'apis/campaign_influencers';

/**
 * Campaign products base screen. Allows to select products if not already selected.
 * If products are already selected, display a recap of the selected products.
 */
export const CampaignProducts = ({ campaignInfluencer }: { campaignInfluencer: FetchCampaignInfluencerResult; }) => {
  const [isEditingProducts, setIsEditingProducts] = useState(false);
  // First check if products are already selected
  const {
    data,
    isLoading,
    isError
  } = useSelectedProducts({ campaignInfluencerId: campaignInfluencer.id });

  if (isLoading) {
    return <Loader $mt={20}>Loading...</Loader>;
  }

  if (isError) {
    return <BloxText $mt={20} $fs_md $cname="slate7" $textAlign="center">Something went wrong, please try again later.</BloxText>;
  }

  const onEditProducts = () => {
    if (!data?.selected_products?.length || !data?.shipping_address) {
      return;
    }
    setIsEditingProducts(true);

    // Write the current selected products and shipping address to session storage
    setProductsInSessionStorage(campaignInfluencer.id, data.selected_products.reduce((collection, product) => ({ ...collection, [product.product_id]: product.variant_id }), {}));
    setShippingAddressInSessionStorage(campaignInfluencer.id, data.shipping_address);
  };

  return Number(data?.selected_products?.length) > 0 && data?.shipping_address && !isEditingProducts ? (
    <CampaignProductsStatus
      campaignInfluencer={campaignInfluencer}
      shippingAddress={data?.shipping_address}
      selectedProducts={data?.selected_products ?? []}
      orderSubmitted={!!data?.brand_order_submitted}
      onEditProducts={onEditProducts}
    />
  ) : (
    <SelectCampaignProducts campaignInfluencer={campaignInfluencer} />
  );
};
