import { useStatus, BloxButton } from '@pixleeturnto/wr4pt';
import React from 'react';
import { useRespondToCampaign } from 'react/apis/campaign_influencers';
import { useCampaignMessages, useSendCampaignMessages } from 'react/apis/campaign_messages';
import { useRailsAuthenticityToken } from 'react/contexts/authenticityToken';
import { trackCampaignMessageFileClick, trackCampaignMessageSent } from 'react/utils/analytics';
import styled from 'styled-components';
import { MessagesList } from './MessagesList';
import { SendActionBar } from './SendActionBar';
import type { FetchCampaignInfluencerResult } from 'apis/campaign_influencers';
import { CampaignInfluencerMethods } from '@pixleeturnto/wr4pt/dist/models';

/** 
 * Campaign Messages section in the campaign details page
 */
export const CampaignMessages = ({ campaignInfluencer }: { campaignInfluencer: FetchCampaignInfluencerResult; }) => {
  const authenticityToken = useRailsAuthenticityToken();

  const campaignInfluencerId = campaignInfluencer.id;

  // Fetching messages
  const {
    data,
    isLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    error
  } = useCampaignMessages({ campaignInfluencerId });

  // Hook for sending messages
  const { mutate: send } = useSendCampaignMessages({
    campaignInfluencerId,
    onError: (error) => showStatus({
      content: error?.data?.join(', ') || 'Something went wrong, please try again later.',
      variant: 'error',
      displayTime: 5000,
    }),
  });

  const { mutate: respondToCampaign, isLoading: isResponding } = useRespondToCampaign({ campaignInfluencerId, campaignId: campaignInfluencer.campaign?.id });

  const { showStatus } = useStatus();

  // useCampaignMessages relies on useInfiniteQuery, which returns data broken down by pages
  // https://tanstack.com/query/v4/docs/guides/infinite-queries
  const allData = data?.pages.flatMap(page => page.messages).reverse();

  const handleSend = ({ message, files }: { message: string, files: File[]; }) => {
    // This triggers the backend call
    send({ message, files, authenticityToken });

    trackCampaignMessageSent(campaignInfluencer.id, campaignInfluencer.campaign?.id);
  };

  const handleFileClick = () => {
    trackCampaignMessageFileClick(campaignInfluencer.id, campaignInfluencer.campaign?.id);
  };

  const handleAccept = () => {
    respondToCampaign({ accept: true, authenticityToken });
  };


  return (
    <>
      {error && <Error>An error has occured while fetching the messages</Error>}
      <Container>
        <MessagesList
          allData={allData}
          pagesCount={data?.pages?.length ?? 0}
          hasNextPage={hasNextPage}
          isFetchingNextPage={isFetchingNextPage}
          isLoading={isLoading}
          fetchNextPage={fetchNextPage}
          onFileClick={handleFileClick}
        />
        {CampaignInfluencerMethods.isPending(campaignInfluencer) && <AcceptPrompt>
          Accept campaign invitation?
          <BloxButton className="text" onClick={handleAccept} disabled={isResponding}>Accept</BloxButton>
        </AcceptPrompt>}
        {CampaignInfluencerMethods.isPending(campaignInfluencer) || CampaignInfluencerMethods.isAccepted(campaignInfluencer) ?
          <SendActionBar onSend={handleSend} /> : null
        }
      </Container>
    </>
  );
};


const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 5px;
  margin-bottom: 10px;
`;

const Error = styled.div.attrs({
  role: 'alert',
})`
  color: var(--blox-red);
  font-size: 12px;
  text-align: center;
  z-index: 1;
  position: relative;
`;

const AcceptPrompt = styled.div`
  background: var(--blox-slate0a);
  padding: 6px 18px;
  border-radius: 5px;
  font-size: 12px;
  color: var(--blox-slate3);
  margin: 8px 0;
  font-weight: 400;
  font-family: var(--blox-font-family);
  display: flex;
  justify-content: space-between;
`;
