import { Controller } from 'stimulus';

class GoalController extends Controller {
  static targets = ['submitButton', 'tosCheckbox', 'goal'];

  /**
   * @returns {boolean} true if any of the goals are checked.
   */
  #goalChecked = () => Array.from(this.goalTargets).some((goalRadioBtn) => goalRadioBtn.checked);

  #updateRegisterDisabled = () => { this.submitButtonTarget.disabled = !this.#goalChecked(); };

  /**
   * This is called whenever a radio button is clicked on the goal page.
   */
  onGoalChanged = () => this.#updateRegisterDisabled();

  connect = () => this.#updateRegisterDisabled();
}

export default GoalController;
