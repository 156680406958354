// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("turbolinks").start()
// require("@rails/activestorage").start()
// require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import '@pixleeturnto/blox';
import 'stylesheets/application.scss';
import 'stylesheets/main.scss';
import 'stylesheets/page.scss';
import 'stylesheets/register_profile.scss';
import 'stylesheets/register_social.scss';
import 'stylesheets/register_interests.scss';
import 'stylesheets/goal.scss';
import 'stylesheets/welcome.scss';
import 'stylesheets/settings.scss';
import 'stylesheets/campaign_influencers.scss';
import 'stylesheets/account.scss';
import 'stylesheets/support.scss';
import 'stylesheets/review.scss';

import 'toastr/build/toastr.css';

// Stimulus Controllers
import 'controllers';

// Global toastr
import Toastr from 'toastr';

// Error Tracking
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';

// React
import ReactOnRails from 'react-on-rails';
import { NavBar } from 'react/components/NavBar';
import { CampaignsList } from 'react/components/campaigns/CampaignsList';
import { CampaignDetails } from 'react/components/campaigns/CampaignDetails';
import { withWrappers } from 'react/utils/withWrappers';
// For wr4pt/PTTForm
import 'informel';

// Analytics
import { segementTrackAllPageViews } from '../analytics';

// This is how react_on_rails can see the HelloWorld in the browser.
ReactOnRails.register({
  CampaignsList: withWrappers(CampaignsList),
  CampaignDetails: withWrappers(CampaignDetails),
  NavBar: withWrappers(NavBar),
});

const setupSentry = () => {
  // Sentry JS Config
  Sentry.init({
    dsn: process.env.PIXLEE_ME_FRONTEND_SENTRY_URL,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
  window.Sentry = Sentry;
};

// Track all page views to keen
if (process.env.NODE_ENV === 'production') {
  segementTrackAllPageViews();
  setupSentry();
}

// Allow global usage of toasters in JS
global.Toastr = Toastr;
Toastr.options = {
  closeButton: true,
  debug: false,
  newestOnTop: false,
  progressBar: false,
  positionClass: 'toast-top-center',
  preventDuplicates: true,
  onclick: null,
  showDuration: '300',
  hideDuration: '1000',
  timeOut: '3000',
  extendedTimeOut: '1000',
  showEasing: 'swing',
  hideEasing: 'linear',
  showMethod: 'fadeIn',
  hideMethod: 'fadeOut',
  onShown: () => {
    const toastDiv = document.getElementById('toast-container').getElementsByTagName('div');
    toastDiv[0].style.opacity = '1';
  },
};

// Start turbo links
const Turbolinks = require('turbolinks');

Turbolinks.start();

// Import ActiveStorage
const ActiveStorage = require('@rails/activestorage');

ActiveStorage.start();

// Start rails UJS
// https://guides.rubyonrails.org/working_with_javascript_in_rails.html#rails-ujs-event-handlers
const RailsUjs = require('@rails/ujs');

RailsUjs.start();
