import { pttFetch } from '@pixleeturnto/wr4pt';
import { ProductAsJson } from 'react/types/product';

export interface FetchCampaignProductsResult {
  products: ProductAsJson[];
}

export const fetchCampaignProducts = async (campaignId: number) => pttFetch<FetchCampaignProductsResult>(`/campaign_products?campaign_id=${campaignId}`, {
  method: 'GET',
  headers: {
    Accept: 'application/json',
  },
});
