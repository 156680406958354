import { Controller } from 'stimulus';

class CampaignLinkController extends Controller {
  static targets = ['linkInput'];

  #linkText = () => this.linkInputTarget.value;

  copyLink = () => {
    Toastr.success('Link copied to clipboard.');
    window.navigator?.clipboard?.writeText(this.#linkText());
  };
}

export default CampaignLinkController;
