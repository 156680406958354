import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Box, BloxButton, BloxLabel, BloxInput, BloxText, Flex, PTTForm, PTTFormField, useStatus } from '@pixleeturnto/wr4pt';
import { useCreateOrUpdateShippingAddress } from 'react/apis/account';
import type { ShippingAddress } from 'apis/account';
import type { FetchCampaignInfluencerResult } from 'apis/campaign_influencers';

export const CampaignInfluencerShippingAddressForm = ({ campaignInfluencer, authenticityToken }: { campaignInfluencer: FetchCampaignInfluencerResult; authenticityToken: string; }) => {
  // shipping address comes from products recap, since the address was already fetched
  // or if the user does not have a default shipping address the inputs will be blank
  const { state } = useLocation();
  const { shippingAddress } = state || {};
  const navigate = useNavigate();

  // if the creator doesn't have a default shipping address, we create one here
  const { mutate: saveDefaultShippingAddress, isLoading } = useCreateOrUpdateShippingAddress();
  const { showError, showSuccess } = useStatus();

  const onSubmitAddress = ({ detail: { values } }: CustomEvent<{ values: ShippingAddress; }>) => {
    if (!shippingAddress) {
      saveDefaultShippingAddress({ shippingAddress: values, authenticityToken }, {
        onSuccess: (_result, params) => {
          setShippingAddressInSessionStorage(campaignInfluencer.id, params.shippingAddress);
          showSuccess('Default shipping address saved!');
          navigate('..');
        },
        onError: () => {
          showError('Error saving default shipping address, please try again later.');
        }
      });
    } else {
      setShippingAddressInSessionStorage(campaignInfluencer.id, values);
      navigate('..');
    }
  };

  return <Box $p={20}>
    <BloxText $cname="slate4" $mb={21} $fs_md>Edit your address</BloxText>
    <PTTForm
      initialValues={shippingAddress}
      onInformSubmit={onSubmitAddress}
    >
      <BloxLabel>
        <BloxText $cname="slate3" $fs_sm>First Name</BloxText>
        <PTTFormField>
          <BloxInput $h={36} $w="100%" $p={10} autoFocus $mt={5} type="text" name="first_name" placeholder="First name" />
        </PTTFormField>
      </BloxLabel>

      <BloxLabel>
        <BloxText $mt={20} $cname="slate3" $fs_sm>Last Name</BloxText>
        <PTTFormField value-missing="Please enter a last name.">
          <BloxInput $h={36} $w="100%" $p={10} $mt={5} type="text" name="last_name" required placeholder="Last name" />
        </PTTFormField>
      </BloxLabel>

      <BloxLabel>
        <BloxText $mt={20} $cname="slate3" $fs_sm>Phone number</BloxText>
        <PTTFormField>
          <BloxInput $mt={5} $w="100%" $h={36} $p={10} type="text" name="phone_number" placeholder="Phone number" />
        </PTTFormField>
      </BloxLabel>

      <BloxLabel>
        <BloxText $mt={20} $cname="slate3" $fs_sm>Address</BloxText>
        <PTTFormField value-missing="Please enter a street address.">
          <BloxInput $mt={5} $w="100%" $h={36} $p={10} type="text" name="address_line_1" required placeholder="Street address" />
        </PTTFormField>
        <PTTFormField>
          <BloxInputWithNoTopBorder type="text" $h={36} $w="100%" $p={10} name="address_line_2" placeholder="Suite/Apt No." />
        </PTTFormField>
      </BloxLabel>

      <BloxLabel>
        <BloxText $mt={20} $cname="slate3" $fs_sm>City</BloxText>
        <PTTFormField value-missing="Please enter a city.">
          <BloxInput $mt={5} $h={36} $w="100%" $p={10} type="text" name="city" required placeholder="City" />
        </PTTFormField>
      </BloxLabel>

      <Flex $mt={20} $gap={10}>
        <BloxLabel $w="100%">
          <BloxText $cname="slate3" $fs_sm>State/Province</BloxText>
          <PTTFormField>
            <BloxInput $mt={5} $h={36} $p={10} $w="100%" type="text" name="state" placeholder="State/Province" />
          </PTTFormField>
        </BloxLabel>
        <BloxLabel $w="100%">
          <BloxText $cname="slate3" $fs_sm>Zip/Postal Code</BloxText>
          <PTTFormField>
            <BloxInput $mt={5} $h={36} $p={10} $w="100%" type="text" name="zip" placeholder="Zip/Postal Code" />
          </PTTFormField>
        </BloxLabel>
      </Flex>

      <BloxLabel>
        <BloxText $mt={20} $cname="slate3" $fs_sm>Country</BloxText>
        <PTTFormField value-missing="Please enter a country.">
          <BloxInput $mt={5} $h={36} $p={10} $w="100%" type="text" name="country" required placeholder="Country" />
        </PTTFormField>
      </BloxLabel>

      <BloxButton $w="100%" type="submit" $mt={20} disabled={isLoading}>Use this address</BloxButton>
    </PTTForm>
  </Box>;
};

const getSessionStorageKey = (campaignInfluencerId: number) => `campaign_influencer_${campaignInfluencerId}_shipping_address`;
export const getShippingAddressFromSessionStorage = (campaignInfluencerId: number): ShippingAddress | null => {
  const data = sessionStorage.getItem(getSessionStorageKey(campaignInfluencerId));
  try {
    const parsedData = data ? JSON.parse(data) : {};
    return parsedData.shippingAddress;
  } catch {
    return null;
  }
};
export const setShippingAddressInSessionStorage = (campaignInfluencerId: number, shippingAddress: ShippingAddress) => {
  sessionStorage.setItem(getSessionStorageKey(campaignInfluencerId), JSON.stringify({ shippingAddress }));
};

const BloxInputWithNoTopBorder = styled(BloxInput)`
  border-top-width: 0 !important;

  ${PTTFormField}[error] + ${PTTFormField} > & {
    border-top-width: 1px !important;
  }
`;
