
/** Formats a number in the format 1000 => 1K */
export const formatCompact = (number) => {
  try {
    return (new Intl.NumberFormat(navigator.language, { notation: 'compact' })).format(number);
  } catch {
    return number.toString();
  }
};


export const formatWithCurrencySymbol = (number, currency = 'USD') => {
  if (!number) {
    return '';
  }
  try {
    return (new Intl.NumberFormat(undefined, { style: 'currency', currency }).format(number));
  } catch {
    return number;
  }
};
