import React, { useState } from 'react';
import type { LayoutProps } from '@pixleeturnto/wr4pt';
import { BloxAnchor, BloxText, Box, Flex, Grid, useStatus, BloxButton, BloxAlert, ProductImage } from '@pixleeturnto/wr4pt';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { AccountAvatar } from './AccountAvatar';
import { useRespondToCampaign } from 'react/apis/campaign_influencers';
import type { FetchCampaignInfluencerResult } from 'apis/campaign_influencers';
import { AccountMethods, CampaignGoalMethods, CampaignInfluencerMethods, CampaignMethods, ProductMethods } from '@pixleeturnto/wr4pt/dist/models';
import { isObjKey } from 'react/types/utils';


const INCENTIVE_METHODS = {
  product: 'Product',
  payment: 'Payment',
  product_and_payment: 'Product & Payment',
  no_incentive: 'No Incentive'
} as const;


export interface CampaignBriefProps {
  campaignInfluencer: FetchCampaignInfluencerResult;
  railsAuthenticityToken: string;
}

/**
 * "Brief" section of the campaign that shows a summary of the campaign.
 */
export const CampaignBrief = ({ campaignInfluencer, railsAuthenticityToken }: CampaignBriefProps) => {
  const { campaign: { hashtags, reward_type, reward_notes, review_email_interval_days, description, campaign_goals, campaign_attachments, handles, phrases, and_hashtags } } = campaignInfluencer;
  const visible_campaign_attachments = campaign_attachments.filter(attachment => attachment.visible);
  const campaignName = CampaignMethods.getName(campaignInfluencer.campaign);
  const starts_at = CampaignMethods.getStartMoment(campaignInfluencer.campaign)?.format('MMM D, YYYY');
  const ends_at = CampaignMethods.getEndMoment(campaignInfluencer.campaign)?.format('MMM D, YYYY');

  const { showSuccess, showError } = useStatus();
  const [showMessage, setShowMessage] = useState(false);
  const { mutate: sendResponseToCampaign, isLoading } = useRespondToCampaign({ campaignInfluencerId: campaignInfluencer.id, campaignId: campaignInfluencer.campaign?.id });

  const respondAcceptCampaign = () => {
    const variables = { accept: true, authenticityToken: railsAuthenticityToken };
    const options = {
      onSuccess: () => showSuccess('Campaign accepted!'),
      onError: () => showError('Request failed!')
    };

    sendResponseToCampaign(variables, options);
  };

  const respondDeclineCampaign = () => {
    setShowMessage(false);
    const variables = { accept: false, authenticityToken: railsAuthenticityToken };
    const options = {
      onSuccess: () => {
        showSuccess('Campaign declined!');
      },
      onError: () => showError('Request failed!')
    };
    sendResponseToCampaign(variables, options);
  };

  const getCampaignStatus = () => {
    if (CampaignInfluencerMethods.isDeclined(campaignInfluencer)) {
      return <BloxText $fs_sm $bold $cname="red">Declined campaign request</BloxText>;
    }

    if (CampaignInfluencerMethods.isPending(campaignInfluencer)) {
      return <BloxText $fs_sm $bold $cname="yellow">Awaiting response</BloxText>;
    }

    return <BloxText $fs_sm $bold $cname="green">Campaign accepted</BloxText>;
  };

  return (
    <StyledBox $f={1}>
      <CampaignHeader>
        <Flex>
          <Box $w='54px' $h='54px' $mr={21} $align="center">
            <AccountAvatar logo={AccountMethods.getLogo(campaignInfluencer.campaign.account) ?? ''} />
          </Box>
          <CampaignNameContainer>
            {CampaignInfluencerMethods.isDeclined(campaignInfluencer) ?
              <BloxDeclinedText $fs_lg>{campaignName}</BloxDeclinedText> :
              <WrappedText $fs_lg $cname="slate5">{campaignName}</WrappedText>}
            <Flex>
              {CampaignInfluencerMethods.isDeclined(campaignInfluencer) ?
                (<BloxDeclinedText $fs_sm $cname="slate5" $my={10}>Timeline:&nbsp;
                  <BloxText as="span" $fs_sm $bold>{starts_at} - {ends_at}</BloxText>
                </BloxDeclinedText>) :
                (<BloxText $fs_sm $cname="slate5" $my={10}>Timeline:&nbsp;
                  <BloxText as="span" $ml={5} $fs_sm $bold $cname="slate4">{starts_at} - {ends_at}</BloxText>
                </BloxText>)}
            </Flex>
            {getCampaignStatus()}
          </CampaignNameContainer>
        </Flex>

        {CampaignInfluencerMethods.isPending(campaignInfluencer) && (<>
          <CampaignButtonGroup role="group">
            <BloxButton disabled={isLoading} onClick={respondAcceptCampaign} $ml={0} $mr={17}>Accept</BloxButton>
            <BloxButton disabled={isLoading} onClick={() => setShowMessage(true)} className='secondary grey' $m={0}>Decline</BloxButton>
          </CampaignButtonGroup>
          <BloxAlert
            data-testid="decline_alert"
            header="Decline invitation?"
            content="Do you want to decline this campaign invitation?"
            show={showMessage}
            actionPrimary='yes'
            actionSecondary='no'
            onActionPrimary={respondDeclineCampaign}
            onActionSecondary={() => setShowMessage(false)}
          />
        </>)}
      </CampaignHeader>

      {!!description &&
        <Wrapper $mt="16px" $lmb="10px" label="Description">
          <WrappedText $fs_sm $cname="slate4">
            {description}
          </WrappedText>
        </Wrapper>
      }

      {!!campaignInfluencer.campaign.products.length &&
        <Wrapper $mt={20} $lmb="10px" $mb={20} label="Available Products & Links">
          {campaignInfluencer.campaign.has_product_for_post && CampaignInfluencerMethods.isAccepted(campaignInfluencer) &&
            !campaignInfluencer.hasChosenProducts && <SelectProductLink className="blox" role="button" to="../products">Select products</SelectProductLink>}
          {campaignInfluencer.campaign.products.map(p => p && <ProductItem key={p.id} product={p} />)}
        </Wrapper>
      }

      <Wrapper $mt={20} $lmb="10px" label="Content Requirements">
        <BloxText $cname="slate3a" $fs_sm $italic $mb={10}>In order for you to complete this campaign, you must create the following content:</BloxText>
        {!!campaign_goals?.length && campaign_goals.map((goal, index) => (<GoalWrapper goal={goal} key={index} />))}

        {!!review_email_interval_days &&
          <BloxGoalContainer>
            <BloxText $fs_sm $bold $mb={5}>Product Reviews</BloxText>
            <GoalsList>
              <GoalListItem>
                <BloxText $fs_sm>Product reviews will be required for this campaign</BloxText>
              </GoalListItem>
            </GoalsList>
          </BloxGoalContainer>
        }

        <CampaignParameters type="and_hashtags" parameters={and_hashtags ?? []} />
        <CampaignParameters type="hashtag" parameters={hashtags ?? []} />
        <CampaignParameters type="mention" parameters={handles ?? []} />
        <CampaignParameters type="keyword" parameters={phrases ?? []} />
      </Wrapper>

      {!!reward_type &&
        <Wrapper $mt={20} $lmb="10px" label="Compensation">
          <BloxText $fs_md $cname="green" $bold>{isObjKey(reward_type, INCENTIVE_METHODS) ? INCENTIVE_METHODS[reward_type] : ''}</BloxText>
          {!!reward_notes && <WrappedText $fs_sm $mt={5} $cname="slate4">
            <BloxText as="span" $fs_sm $bold>Notes:&nbsp;</BloxText>
            {reward_notes}
          </WrappedText>}
        </Wrapper>
      }

      {!!visible_campaign_attachments.length &&
        <Wrapper $mt={20} $lmb="10px" label="Assets">
          {visible_campaign_attachments.map(({ name, file_url }) => (<Box key={file_url} $ml={10}><BloxAnchor target="_blank" href={file_url ?? ''}>{name}</BloxAnchor></Box>))}
        </Wrapper>
      }
    </StyledBox>
  );
};

interface CampaignParametersProps {
  type: 'and_hashtags' | 'hashtag' | 'mention' | 'keyword';
  parameters?: string[];
}

/**
 * Campaign Parameters Wrapper
 */
const CampaignParameters = ({ type, parameters = [] }: CampaignParametersProps) => {
  if (!parameters.length) {
    return null;
  }

  return (
    <>
      <BloxText $fs_sm $my={10} $cname="slate4">
        {type === 'and_hashtags' ? 'All content must include one of these hashtag(s):' : `Content can also include any of these ${type}(s):`}
      </BloxText>
      <Flex $gap={8} $fw="wrap">
        {parameters.map((param, i) => <Parameter key={i}>
          <EllipseText $fs_md $cname="slate5" $bold>{param}</EllipseText>
        </Parameter>)}
      </Flex>
    </>
  );
};


interface WrapperProps extends LayoutProps {
  label: string;
  children?: React.ReactNode;
  $lmb?: LayoutProps['$mb'];
}
/**
 * Wrapper for the campaign brief section.
 */
const Wrapper = ({ label, children = null, $lmb = undefined, ...rest }: WrapperProps) => {
  return (
    <WhiteWrapper $p={20}  {...rest}>
      <BloxText $mb={$lmb} $bold $fs_md $cname="slate5">{label}</BloxText>
      {children}
    </WhiteWrapper>
  );
};

const getSourceDisplayName = (source: string | null) => {
  if (source === 'tiktok') {
    return 'TikTok';
  } else if (source === 'instagram') {
    return 'Instagram';
  }
};

interface GoalWrapperProps {
  goal: {
    goals: Record<string, unknown> | null;
    notes: string | null;
    source: string | null;
  };
}

/**
 * Renders a goal for the campaign brief section.
 */
const GoalWrapper = ({ goal }: GoalWrapperProps) => {
  const { source, goals, notes } = goal;

  return (
    <BloxGoalContainer>
      <BloxText $fs_sm $bold $mb={5}>{getSourceDisplayName(source)}</BloxText>
      <GoalsList>
        {Object.keys(goals ?? {}).map((key) => <GoalList key={key} goalName={key} source={source} goalValue={goals?.[key]} />)}
      </GoalsList>
      {!!notes &&
        <Box>
          <BloxText $fs_sm $bold $m="8px 0 5px">Notes from the brand</BloxText>
          <WrappedText $fs_sm>{notes}</WrappedText>
        </Box>
      }
    </BloxGoalContainer>
  );
};


interface GoalListProps {
  goalName: string;
  source: string | null;
  goalValue: unknown;
}

/**
 * Draw a list of goals for a goal source.
 */
const GoalList = ({ goalName, source, goalValue }: GoalListProps) => {
  const goalDisplayName = getGoalDisplayName(goalName, source);

  const lookup = {
    'stories': 'story',
    'carousel posts': 'carousel post',
    'reels': 'reel',
    'photos': 'photo',
    'TikToks': 'TikTok',
  } as const;

  const makeSingular = (str: string, num: number) => {
    return Number(num) === 1 && isObjKey(str, lookup) ? lookup[str] : str;
  };

  const parsedGoalValue = CampaignGoalMethods.parseGoal(goalName, goalValue);
  const isCarousel = parsedGoalValue && typeof parsedGoalValue === 'object';


  return (<GoalListItem>
    <Flex $align="baseline">
      <BloxText as="span" $fs_sm $bold>{isCarousel ? parsedGoalValue.post : parsedGoalValue}&nbsp;</BloxText>
      <BloxText as="span" $fs_sm>{source === 'instagram' ? 'Instagram' : ''}&nbsp;</BloxText>
      {isCarousel ? <BloxText as="span" $fs_sm>
        {makeSingular('carousel posts', parsedGoalValue.post ?? 0)}&nbsp;with&nbsp;
        <BloxText as="span" $fs_sm $bold>{parsedGoalValue.content}</BloxText>&nbsp;content
      </BloxText> :
        <BloxText as="span" $fs_sm>{makeSingular(goalDisplayName, parsedGoalValue ?? 0)}</BloxText>}
    </Flex>
  </GoalListItem>);
};

/**
 * Draw a list of goals for a goal source.
 */
const ProductItem = ({ product }: { product: { product_photo: string | null, name: string | null, buy_now_link_url: string | null; }; }) => {
  const [isCopied, setIsCopied] = useState(false);
  const { showError } = useStatus();

  const onCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(ProductMethods.getBuyNowLinkUrl(product) ?? '');
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 3000);
    } catch (err) {
      showError('The copy failed, please ensure you have the right permissions.');
    }
  };

  return (
    <ProductContainer $columns="65px 1fr" $mb={12}>
      <ProductImage photoUrl={ProductMethods.getPhotoUrl(product) ?? ''} productName={ProductMethods.getName(product) ?? ''} $w={50} $h={50} />
      <ProductInfo>
        <BloxText $fs_md $cname="slate5" $mb={5}>{ProductMethods.getName(product)}</BloxText>
        {!!ProductMethods.getBuyNowLinkUrl(product) &&
          <Flex $alignItems="center">
            <ProductUrl href={ProductMethods.getBuyNowLinkUrl(product) ?? ''} target="_blank" rel="noopener noreferrer">{ProductMethods.getBuyNowLinkUrl(product)}</ProductUrl>
            {isCopied ? <BloxText $cname="slate5" $fs_sm $bold $pl={3}>Copied!</BloxText> : <BloxButton className="text" onClick={onCopyClick}><LinkIcon /></BloxButton>}
          </Flex>
        }
      </ProductInfo>
    </ProductContainer>
  );
};


function getGoalDisplayName(goalName: string, source: string | null) {
  if (source === 'tiktok') {
    return 'TikToks';
  }
  if (goalName === 'videos') {
    return 'reels';
  }
  return goalName;
}

const StyledBox = styled(Box)`
  background-color: var(--page-background);
`;

const WhiteWrapper = styled(Box)`
  background-color: white;
`;

const CampaignHeader = styled(Flex).attrs({
  $fd: 'column',
  $p: '20px 30px',
})`
  background-color: white;
`;

const GoalsList = styled.ul`
  list-style: none;
  margin-left: 10px;
`;

const GoalListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;

  &::before {
    content: '';
    background: var(--blox-slate5);
    width: 4px;
    height: 4px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 10px;
  }
`;

const CampaignNameContainer = styled.div`
  padding-top: 4px;
  overflow: hidden;
`;

const WrappedText = styled(BloxText)`
  overflow-wrap: break-word;
  white-space: break-spaces;
  color: black;
`;

const BloxDeclinedText = styled(WrappedText)`
  color: var(--blox-slate4--active);
  text-decoration: line-through;
  opacity: 0.3;
`;

const BloxGoalContainer = styled(Box)`
  color: var(--blox-slate5);
  margin: 11px 0;
`;

const CampaignButtonGroup = styled(Flex).attrs({
  $justify: 'center',
  $pt: 16,
  $f: 1,
  $align: 'center',
  $w: '100%'
})`
  background-color: white;

  button {
      margin: auto;
      width: 100%;
  }
`;

const Parameter = styled.div`
  border: 1px solid var(--blox-slate1c);
  border-radius: 3px;
  overflow: hidden;
  padding: 5px 10px;
`;

const EllipseText = styled(BloxText)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProductUrl = styled(BloxAnchor)`
  display: inline-block;
  font-size: var(--blox-font-size-sm) !important;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-right: 7px;
  max-width: 75%;
`;

const LinkIcon = styled.i.attrs({
  className: 'fa-regular fa-link-simple',
})`
  font-size: 18px !important;
  color: var(--blox-slate5);
  transform: rotate(130deg);
`;

const ProductContainer = styled(Grid).attrs({
  $columns: '65px 1fr',
  $mt: 10,
  $p: 5,
})`
  border: 0.5px solid var(--blox-slate1c);
  border-radius: 5px;
`;

const ProductInfo = styled(Flex).attrs({
  $fd: 'column',
  $h: '100%',
  $justify: 'center'

})`
  justify-self: unset;
  overflow: hidden;
`;

const SelectProductLink = styled(Link).attrs<{ $disabled?: boolean; }>(props => ({
  className: `blox${props.$disabled ? ' disabled' : ''}`,
  role: 'button',
  'aria-disabled': !!props.$disabled,
})) <{ $disabled?: boolean; }>`
  width: 100% !important;
  ${props => props.$disabled ? 'pointer-events: none;' : ''}
`;
