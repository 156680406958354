import { Controller } from 'stimulus';

/**
 * Properties used for setting the contents of the dialog. These properties must match
 * one of the AccountController.targets that begins with "dialog".
 * @typedef DialogContents
 * @type {{cancelButton: string, title: string, confirmButton: string, content: string}}
 */

/**
 * The contents of the dialog that pops up when a user initiates an action to deactivate their account.
 * @type {DialogContents}
 */
const deactivateDialogContents = {
  title: 'Deactivate Account?',
  content: 'Deactivating your account disables campaign offers and messaging.',
  confirmButton: 'YES, DEACTIVATE',
  cancelButton: 'NO, KEEP',
};

/**
 * The contents of the dialog that pops up when a user initiates an action to delete their account.
 * @type {DialogContents}
 */
const deleteAccountDialogContents = {
  title: 'Delete Account?',
  content: 'Deleting your account permanently removes all of your settings and historical data.',
  confirmButton: 'YES, DELETE',
  cancelButton: 'NO, KEEP',
};

/**
 * The contents of the dialog that pops up when a user initiates an action to delete their avatar.
 * @type {DialogContents}
 */
const deleteAvatarDialogContents = {
  title: 'Delete profile photo?',
  content: 'Deleting your profile photo deletes it from your account permanently.',
  confirmButton: 'YES, DELETE',
  cancelButton: 'NO, KEEP',
};

class AccountController extends Controller {
  static targets = [
    'avatar',
    'photoForm',
    'deactivationLink',
    'deactivatedDialog',
    'deletionLink',
    'deletedDialog',
    'dialogTitle',
    'dialogContent',
    'dialogConfirmButton',
    'dialogCancelButton',
    'dialogWrapper',
  ];

  /**
   * Applies the given contents to the dialog popup.
   * @param {DialogContents} contents
   */
  #applyDialogContents = (contents = {}) => Object
    .entries(contents)
    .forEach(([key, value]) => {
      const keyWithUppercaseFirstLetter = key.charAt(0).toUpperCase() + key.slice(1);
      this[`dialog${keyWithUppercaseFirstLetter}Target`].innerText = value;
    });

  #isDeactivateDialog = () => this.dialogTitleTarget.innerText === deactivateDialogContents.title;

  #isDeleteAvatarDialog = () => this.dialogTitleTarget.innerText === deleteAvatarDialogContents.title;

  #deactivateAccountDialog = () => this.#applyDialogContents(deactivateDialogContents);

  #deleteAvatarDialog = () => this.#applyDialogContents(deleteAvatarDialogContents);

  #isDeleteAccountDialog = () => this.dialogTitleTarget.innerText === deleteAccountDialogContents.title;

  #deleteAccountDialog = () => this.#applyDialogContents(deleteAccountDialogContents);

  /**
   * This is for closing the dialogs that are used as warning prompts
   */
  #closeDialog = () => {
    setTimeout(() => {
      this.dialogWrapperTarget.classList.add('hidden');
    }, 101);
    this.dialogWrapperTarget.classList.add('no_opacity');
  };

  #openDialog = () => {
    this.dialogWrapperTarget.classList.remove('hidden');
    this.dialogWrapperTarget.classList.remove('no_opacity');
  };

  connect = () => {
    this.#deactivateAccountDialog();
    this.avatarTarget.addEventListener('change', this.onAvatarChange);
  };

  disconnect = () => this.avatarTarget.removeEventListener('change', this.onAvatarChange);

  onDeletePhotoClick = () => {
    this.#deleteAvatarDialog();
    this.#openDialog();
  };

  onDeactivateClick = (e) => {
    e.preventDefault();
    this.#deactivateAccountDialog();
    this.#openDialog();
  };

  onDeleteAccountClick = () => {
    this.#deleteAccountDialog();
    this.#openDialog();
  };

  onCameraIconClick = () => this.avatarTarget.click();

  onEditIconClick = () => this.avatarTarget.click();

  onAvatarChange = () => {
    if (this.avatarTarget.value) {
      this.photoFormTarget.submit();
    }
  };

  onDialogCancel = () => {
    this.#closeDialog();
  };

  onDialogOpen = () => {
    this.#openDialog();
  };

  onDialogConfirm = () => {
    if (this.#isDeactivateDialog()) {
      this.deactivationLinkTarget.click();
    } else if (this.#isDeleteAvatarDialog()) {
      this.photoFormTarget.submit();
    }
    if (this.#isDeleteAccountDialog()) {
      this.deletionLinkTarget.click();
    }
  };

  /**
   * This is for the dialog that shows after the account has been deactivated
   */
  onDeactivatedDialogCloseClick = () => {
    this.deactivatedDialogTarget.classList.add('hidden');
    window.Toastr.warning('Your account is deactivated.');
  };
}

export default AccountController;
