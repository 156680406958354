import { fetchCampaignProducts } from 'apis/campaign_products';
import { useQuery } from 'react-query';

/**
 * Hook for fetching a single campaign influencer
 */
export const useCampaignProducts = ({ campaignId, enabled }: { campaignId: number, enabled?: boolean; }) => {
  return useQuery({
    queryKey: ['campaignProducts', campaignId],
    queryFn: async () => {
      const { data } = await fetchCampaignProducts(campaignId);

      return data.products;
    },
    enabled
  });
};
