// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

const application = Application.start();
const context = require.context('controllers', true, /_controller\.js$/);
application.load(definitionsFromContext(context));

if (process.env.NODE_ENV === 'production') {
  // memorize default handler
  const defaultErrorHandler = application.handleError.bind(application);

  // configure Sentry to log errors and prepend the default handler
  const sentryErrorHandler = (error, message, detail = {}) => {
    defaultErrorHandler(error, message, detail);
    window.Sentry.captureException(error, { message, ...detail });
  };

  // overwrite the default handler with our new composed handler
  application.handleError = sentryErrorHandler;
}
