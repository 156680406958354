import { getShippingAddress, createOrUpdateShippingAddress } from 'apis/account';
import type { ShippingAddress } from 'apis/account';
import { useQuery, useMutation } from 'react-query';


export const useShippingAddress = ({ enabled }: { enabled?: boolean; }) => {
  return useQuery({
    queryKey: ['shippingAddress'],
    queryFn: async () => {
      const { data } = await getShippingAddress();
      return data;
    },
    enabled
  });
};

export const useCreateOrUpdateShippingAddress = () => {
  return useMutation(({ shippingAddress, authenticityToken }: { shippingAddress: ShippingAddress; authenticityToken: string }) => createOrUpdateShippingAddress({ shippingAddress, authenticityToken }));
};