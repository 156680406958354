import type {
  SaveCreateProductsParams,
  FetchCampaignInfluencerProductsResult,
  FetchCampaignInfluencerProductsParams,
} from 'apis/campaign_influencer_products';
import { saveCreatorProducts, fetchCampaignInfluencerProducts } from 'apis/campaign_influencer_products';
import { useMutation, useQuery } from 'react-query';

export const getSelectedProductsKey = (campaignInfluencerId: number) => ['campaignInfluencerProducts', campaignInfluencerId];

/**
 * Hook to save the chosen products for an influencer
 */
export const useSaveCreatorProducts = () => {
  return useMutation(({
    campaignInfluencerId,
    products,
    shippingAddress,
    authenticityToken,
  }: SaveCreateProductsParams) => saveCreatorProducts({ campaignInfluencerId, products, authenticityToken, shippingAddress }));
};

/**
 * Hook to get the chosen products for an influencer
 */
export const useSelectedProducts = ({ campaignInfluencerId }: FetchCampaignInfluencerProductsParams) => {
  return useQuery(getSelectedProductsKey(campaignInfluencerId), async (): Promise<FetchCampaignInfluencerProductsResult> => {
    const { data } = await fetchCampaignInfluencerProducts({ campaignInfluencerId });

    return data;
  });
};
