import { Controller } from 'stimulus';

export class SocialInstagramController extends Controller {
  static targets = ['submit', 'accountCheckbox'];

  /**
   * This method is triggered whenever an input event is fired
   * from one of the accountCheckboxes that is found in
   * one of the _social_instagram_account.html.erb partials.
   * @param {InputEvent} event
   */
  onSelectAccount = ({ target }) => {
    this.accountCheckboxTargets.forEach((checkboxTarget) => {
      // Make sure whatever checkboxes there are other than
      // the one that was checked is set to false so that it
      // can act as a toggle.
      if (!Object.is(checkboxTarget, target)) {
        checkboxTarget.checked = false;
      }
    });
    this.submitTarget.disabled = !target?.checked ?? true;
  };
}

export default SocialInstagramController;
