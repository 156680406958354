import { pttFetch } from '@pixleeturnto/wr4pt';
import type { CampaignMessageRecord } from '@pixleeturnto/wr4pt/dist/models';

const MESSAGES_PAGE_SIZE = 10;

/**
 * result of the rails view below (views/campaign_messages/index.rabl):
 * array of attributes :id, :subject, :body, :created_at, :created_by_user_id, :read
 * node(:attachments) do |campaign_message|
 *   campaign_message.attachments.map do |attachment|
 *     {
 *       id: attachment.id,
 *       filename: attachment.filename.to_s,
 *       url: rails_blob_url(attachment, only_path: true, disposition: 'attachment'),
 *       byte_size: attachment.byte_size,
 *       human_size: number_to_human_size(attachment.byte_size)
 *     }
 *   end
 * end
 */
export type SingleMessage = Pick<CampaignMessageRecord, 'id' | 'subject' | 'body' | 'created_at' | 'created_by_user_id' | 'read'> & {
  attachments: Array<{
    id: number;
    filename: string;
    url: string;
    byte_size: number;
    human_size: string;
  }>;
};

export interface FetchCampaignMessagesResult {
  messages: SingleMessage[];
  offset: number;
  total_count: number;
}

export const fetchCampaignMessages = ({ campaignInfluencerId, pageParam = 0 }: { campaignInfluencerId: number, pageParam: number; }) => pttFetch<FetchCampaignMessagesResult>(
  `/campaign_messages?campaign_influencer_id=${campaignInfluencerId}&limit=${MESSAGES_PAGE_SIZE}&offset=${pageParam}`,
);

export interface SendCampaignMessagesParams {
  campaignInfluencerId: number;
  message: string;
  files?: File[];
  authenticityToken: string;
}

export const sendCampaignMessages = async ({
  campaignInfluencerId,
  message = '',
  files = [],
  authenticityToken, // the authenticity token is given by rails
}: SendCampaignMessagesParams) => {
  const formData = new FormData();

  formData.append('authenticity_token', authenticityToken);
  formData.append('campaign_influencer_id', campaignInfluencerId?.toString() ?? '');
  formData.append('campaign_message[body]', message);
  files.forEach((file) => {
    formData.append('campaign_message[attachments][]', file);
  });

  const { data } = await pttFetch<SingleMessage>('/campaign_messages', {
    method: 'POST',
    body: formData,
  });

  return data;
};
