import React from 'react';
import { BloxAnchor, BloxText } from '@pixleeturnto/wr4pt';
import styled from 'styled-components';
import { trackMenuNavigate } from 'react/utils/analytics';

export const NavBar = ({ title, backLink }: { title?: string, backLink: string; }) => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const firstLinkRef = React.useRef<HTMLAnchorElement>(null);
  const lastLinkRef = React.useRef<HTMLAnchorElement>(null);
  const buttonRef = React.useRef<HTMLButtonElement>(null);

  React.useEffect(() => {
    if (isMenuOpen) {
      firstLinkRef.current?.focus();
    } else {
      buttonRef.current?.focus();
    }
  }, [isMenuOpen]);

  // When backLink is provided:
  // - the back button is shown
  // - the menu button is hidden
  // - the title is centered
  // When backLink is not provided:
  // - the back button is hidden
  // - the menu button is shown
  // - the title is left aligned

  const handleKeyDown = (e: React.KeyboardEvent<HTMLUListElement>) => {
    if (e.key === 'Escape') {
      setIsMenuOpen(false);
    }

    const target = e.target as HTMLElement;
    if (e.key === 'Tab') {
      // Focus trap: when tabbing away from the last link, focus the first link and vice versa
      if (target === firstLinkRef.current && e.shiftKey) {
        e.preventDefault();
        lastLinkRef.current?.focus();
      } else if (target === lastLinkRef.current && !e.shiftKey) {
        e.preventDefault();
        firstLinkRef.current?.focus();
      }
    }
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLUListElement, MouseEvent>) => {
    trackMenuNavigate((e.target as HTMLUListElement).textContent);
  };

  return (
    <Container>
      {backLink ? (
        <>
          <BackLink href={backLink}>
            <BackArrowIcon />
          </BackLink>
          <TitleText $fs_lg $bold>{title}</TitleText>
          {/* Empty div for centering the title with space-between */}
          <div></div>
        </>
      ) : (
        <YourCampaignsText $bold>{title}</YourCampaignsText>
      )}

      {!backLink && (
        <>
          <Button ref={buttonRef} onClick={() => setIsMenuOpen(val => !val)} aria-label="Navigation menu" aria-expanded={isMenuOpen} aria-haspopup="true">
            <CogIcon />
          </Button>
          {isMenuOpen && <DropDown role="menu" onKeyDown={handleKeyDown} onClick={handleMenuClick}>
            <li role="menuitem"><a ref={firstLinkRef} href="/account" >Account</a></li>
            <li role="menuitem"><a href="/campaign_influencers">Campaigns</a></li>
            <li role="menuitem"><a href="https://pixlee.me/privacy-policy" target="_blank" rel="noreferrer" >Pixlee TOS</a></li>
            <li role="menuitem"><a href="/support" >Support</a></li>
            <li role="menuitem"><a ref={lastLinkRef} href="/logout" >Sign Out</a></li>
          </DropDown>}
        </>
      )}
    </Container>
  );

};

const PADDING_RIGHT = 20;

const Container = styled.div`
  background: white;
  padding: 19px ${PADDING_RIGHT}px 19px 29px;
  display: flex;
  gap: 20px;
  justify-content: space-between;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const TitleText = styled(BloxText)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const YourCampaignsText = styled(TitleText)`
  font-size: 18px;
  color: black;
`;

const CogIcon = styled.i.attrs({
  className: 'fal fa-cog',
})`
  font-size: 19px;
`;

const BackArrowIcon = styled.i.attrs({
  className: 'fal fa-arrow-left',
})`
  font-size: 24px;
`;

const BackLink = styled(BloxAnchor)`
  color: black !important;
`;

const Button = styled.button`
  background: none;
  width: auto;
  height: auto;
  color: black;
`;


const DropDown = styled.ul`
  display: flex;
  background: white;
  border-radius: 3px;
  box-shadow: 0px 0px 5px #0000001a;
  flex-direction: column;
  position: absolute;
  right: ${PADDING_RIGHT}px;
  top: 60px;
  z-index: 1;

  li {
    &:first-child a {
      border-radius: 3px 3px 0px 0px;
    }

    &:last-child a {
      border-radius: 0px 0px 3px 3px;
    }
  }

  a {
    color: var(--blox-slate4);
    display: block;
    font-family: var(--blox-font-family);
    font-size: 14px;
    padding: 13px 25px 10px 15px;
    text-align: left;

    &:hover {
      background: var(--blox-blue);
      color: white;
    }
  }
`;
