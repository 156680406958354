import { pttFetch } from '@pixleeturnto/wr4pt';
import { serialize as toFormData } from 'object-to-formdata';

export interface ShippingAddress {
  id: number;
  first_name: string | null;
  last_name: string;
  address_line_1: string | null;
  address_line_2: string | null;
  city: string;
  state: string | null;
  zip: string | null;
  country: string;
  phone_number: string | null;
}

export interface CreateOrUpdateShippingAddressResult {
  shipping_address: ShippingAddress | null;
}

export const getShippingAddress = () => pttFetch<ShippingAddress>('/account/shipping_address', {
  method: 'GET',
  headers: {
    Accept: 'application/json',
  },
});

export const createOrUpdateShippingAddress = ({ shippingAddress, authenticityToken }: { shippingAddress: ShippingAddress, authenticityToken: string }) => pttFetch<CreateOrUpdateShippingAddressResult>('/account/shipping_address', {
  method: 'POST',
  headers: {
    Accept: 'application/json',
    'X-CSRF-Token': authenticityToken,
  },
  // the controller method is also being used on the account settings screen, it expects form data format
  body: toFormData({ shipping_address: shippingAddress, redirect: false }),
});
