import React from 'react';
import { useInfiniteQuery, useIsMutating, useMutation, useQueryClient } from 'react-query';
import { useEvent } from '@pixleeturnto/wr4pt';
import type {
  SendCampaignMessagesParams,
  SingleMessage
} from 'apis';
import {
  fetchCampaignMessages,
  sendCampaignMessages,
} from 'apis';
import moment from 'moment';

const REFETCH_INTERVAL_SEC = 10;

const getCampaignMessagesQueryKey = (campaignInfluencerId: number) => ['messages', campaignInfluencerId];
const getSendMutationKey = (campaignInfluencerId: number) => ['sendMessage', campaignInfluencerId];

export type TransformedSingleMessage = SingleMessage & {
  $$uiPending: boolean;
};
/**
 * Fetches the messages for a campaign (paginated)
 */
export const useCampaignMessages = ({ campaignInfluencerId }: { campaignInfluencerId: number; }) => {
  const queryKey = getCampaignMessagesQueryKey(campaignInfluencerId);

  const query = useInfiniteQuery(queryKey, async ({ pageParam = 0 }) => {
    const response = await fetchCampaignMessages({ campaignInfluencerId, pageParam });

    return {
      ...response.data,
      messages: response.data.messages?.map(m => {
        const result: TransformedSingleMessage = {
          ...m,
          $$uiPending: false, // Adding this flag to the message so we can show a "sending" indicator
        };
        return result;
      }) ?? [],
    };
  }, {
    getNextPageParam: (lastPage) => {
      // This is used by react-query to fetch the next page
      // What is returned by this function is the parameter (pageParam above) to fetch the next page
      // If there are no more pages, null is returned, and react-query will stop fetching (and update the `hasMorePages` flag)
      // https://tanstack.com/query/v4/docs/guides/infinite-queries
      const newOffset = lastPage.offset + lastPage.messages.length;
      return newOffset < lastPage.total_count ? newOffset : null;
    }
  });

  const { refetch, isError } = query;

  const isSending = useIsMutating(getSendMutationKey(campaignInfluencerId));

  const handleRefetch = useEvent(() => {
    if (!isSending && !isError) {
      // We don't do this while we're sending a message as it would erase 
      // the mock message we inserted in onMutate below
      refetch({ refetchPage: (_page, index) => index === 0 });
    }
  });

  // Refetching the first page every 10 seconds to look for new messages,
  // so that new messages "appear" to the user, without having to reload the page
  React.useEffect(() => {
    const interval = setInterval(() => {
      handleRefetch();
    }, REFETCH_INTERVAL_SEC * 1000);
    return () => clearInterval(interval);
  }, [handleRefetch]);

  return query;
};


type SendCampaignMessagesError = { data?: string[]; } | undefined;

/**
 * Hook for sending a message
 */
export const useSendCampaignMessages = ({ campaignInfluencerId, onError }: { campaignInfluencerId: number, onError?: (error: SendCampaignMessagesError) => void; }) => {
  const queryClient = useQueryClient();
  return useMutation<SingleMessage, SendCampaignMessagesError, Omit<SendCampaignMessagesParams, 'campaignInfluencerId'>>(({
    message = '',
    files = [],
    authenticityToken, // the authenticity token is given by rails
  }) => sendCampaignMessages({ campaignInfluencerId, message, files, authenticityToken }), {
    mutationKey: getSendMutationKey(campaignInfluencerId), // We are using a mutation key just so that we can call isMutating() above
    onMutate: async ({ message, files }) => { // This is called when the backend call is initiated     

      // Updating the local cache so that the new message shows up immediately
      const queryKey = getCampaignMessagesQueryKey(campaignInfluencerId);

      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries(queryKey);

      const mockMessageId = -1 * Math.random(); // Negative number to avoid collisions with real ids

      queryClient.setQueryData<ReturnType<typeof useCampaignMessages>['data']>(queryKey, (currentData) => {
        const [firstPage, ...allPagesButFirst] = currentData?.pages ?? [];

        return {
          ...currentData,
          pageParams: currentData?.pageParams ?? [],
          pages: [{
            ...firstPage,
            messages: [{
              id: mockMessageId,
              $$uiPending: true, // add a "loading" flag so that we can show it differently in the ui
              body: message,
              created_at: moment().toISOString(),
              created_by_user_id: null,
              read: false,
              subject: '',
              attachments: (files ?? []).map((file, index) => ({
                id: index,
                filename: file.name,
                url: '',
                byte_size: file.size,
                human_size: ''
              }))
            },
            ...(firstPage?.messages ?? []),
            ],
            total_count: (firstPage?.total_count || 0) + 1,
            offset: (firstPage?.offset || 0),
          },
          ...allPagesButFirst
          ]
        };
      });

      // This will be passed to onSettled below
      return mockMessageId;
    },
    onError: (error, _variables, mockMessageId) => {
      // In case of error, just remove the mock message
      const queryKey = getCampaignMessagesQueryKey(campaignInfluencerId);

      // We replace the message with id mockMessageId with the new message
      queryClient.setQueryData<ReturnType<typeof useCampaignMessages>['data']>(queryKey, (currentData) => {
        return {
          ...currentData,
          pageParams: currentData?.pageParams ?? [],
          pages: (currentData?.pages ?? []).map(page => ({
            ...page,
            messages: page.messages?.filter(m => m.id !== mockMessageId) ?? []
          }))
        };
      });

      if (onError) {
        onError(error);
      }
    },
    onSuccess: (response, _variables, mockMessageId) => {
      // The backend returns the new message in the response, 
      // so we replace the mock message with the real one
      const queryKey = getCampaignMessagesQueryKey(campaignInfluencerId);

      // We replace the message with id mockMessageId with the new message
      queryClient.setQueryData<ReturnType<typeof useCampaignMessages>['data']>(queryKey, (currentData) => {
        const newMessage: TransformedSingleMessage = {
          ...response,
          $$uiPending: false,
        };

        return {
          ...currentData,
          pageParams: currentData?.pageParams ?? [],
          pages: (currentData?.pages ?? []).map(page => ({
            ...page,
            messages: response ? page.messages?.map(m => m.id === mockMessageId ? newMessage : m) ?? [] : page.messages
          }))
        };
      });
    },
  });
};
