/*
 * Handles validation on shipping address fields.
 */
import { Controller } from 'stimulus';

class ShippingAddressController extends Controller {
  static targets = ['submit'];

  validateForm = (e) => {
    const informEl = e.target.closest('inform-el');
    // prevent rails.ujs from sending the data if the form is invalid
    if (informEl.invalid) {
      e.preventDefault();
    }
  };
}

export default ShippingAddressController;
