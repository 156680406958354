// Central location for all segment analytic calls
import { Controller } from 'stimulus';
import { segmentTrack } from '../analytics';

// Stimulus controller designated for analytic events to be bounds to templates
export default class extends Controller {
  // app/views/in/welcome/brand_landing_page.html.erb
  accessWithFacebook = () => {
    segmentTrack('Access With Facebook');
  };

  accessWithTiktok = () => {
    segmentTrack('Access With Tiktok');
  };

  // app/views/in/account/index.html.erb
  accountMenuClick = (event) => {
    const props = {
      menu_item: {
        name: event.currentTarget.text,
      },
    };
    segmentTrack('Account Menu Click', props);
  };

  // app/views/in/account/profile.html.erb
  // app/views/in/account/additional_information.html.erb
  accountEdit = (event) => {
    const props = {
      option: {
        name: event.currentTarget.dataset.name,
      },
    };
    segmentTrack('Account Edit', props);
  };

  // app/views/in/registration/social.html.erb
  // app/views/in/account/social.html.erb
  connectSocialAccountClick = (event) => {
    const props = {
      page: {
        category: event.currentTarget.dataset.analyticsCategory,
      },
      social_account: {
        source: event.currentTarget.dataset.source,
      },
    };
    segmentTrack('Connect Social Account Click', props);
  };

  // app/views/in/registration/social.html.erb
  // app/views/in/account/social.html.erb
  disconnectSocialAccountClick = (event) => {
    const props = {
      page: {
        category: event.currentTarget.dataset.analyticsCategory,
      },
      social_account: {
        source: event.currentTarget.dataset.source,
      },
    };
    segmentTrack('Disconnect Social Account Click', props);
  };

  // app/views/in/settings/index.html.erb
  accountNotificationToggle = (event) => {
    const props = {
      toggle: {
        value: event.currentTarget.checked,
      },
    };
    segmentTrack('Account Notification Toggle', props);
  };

  // app/views/in/account/index.html.erb
  deactivateAccount = () => {
    segmentTrack('Deactivate Account');
  };

  // app/views/in/shared/_dialog.html.erb
  acceptDialogLightbox = (event) => {
    const { analyticsName } = event.currentTarget.dataset;
    if (!analyticsName) {
      return;
    }
    const props = {
      dialog: {
        name: analyticsName,
      },
    };
    segmentTrack('Accept Dialog Lightbox', props);
  };

  // app/views/in/shared/_dialog.html.erb
  declineDialogLightbox = (event) => {
    const { analyticsName } = event.currentTarget.dataset;
    if (!analyticsName) {
      return;
    }
    const props = {
      dialog: {
        name: analyticsName,
      },
    };
    segmentTrack('Decline Dialog Lightbox', props);
  };

  // app/views/in/account/index.html.erb
  accountDeleteClick = () => {
    segmentTrack('Account Delete Click');
  };

  // app/views/in/account/additional_information.html.erb
  // app/views/in/registration/register.html.erb
  // app/views/in/registration/index.html.erb
  // app/views/in/registration/interests.html.erb
  registrationSubmit = (event) => {
    const props = {
      page: {
        name: event.currentTarget.dataset.analyticsPage,
      },
    };
    segmentTrack('Registration Submit', props);
  };

  // app/views/in/shared/_nav_bar.html.erb
  navbarBackClick = (event) => {
    const { analyticsCategory } = event.currentTarget.dataset;
    if (!analyticsCategory) {
      return;
    }
    const props = {
      page: {
        category: analyticsCategory,
        current_path: window.location.pathname,
        back_path: event.currentTarget.pathname,
      },
    };
    segmentTrack('Navbar Back Click', props);
  };
}

// Analytic events that are imported into other javascript controllers go below here
// eg) if an event needs some prior logical evaluation before triggering

// app/javascript/controllers/registration/interests_controller.js
export const analyticsCategoriesError = () => {
  segmentTrack('Categories Error');
};
