import React from 'react';
import { Flex, BloxText } from '@pixleeturnto/wr4pt';
import styled from 'styled-components';
import moment from 'moment';
import { trackCampaignClick } from 'react/utils/analytics';
import { AccountAvatar } from './AccountAvatar';
import type { AccountRecord, CampaignInfluencerRecord, CampaignRecord } from '@pixleeturnto/wr4pt/models';
import { AccountMethods, CampaignInfluencerMethods, CampaignMethods } from '@pixleeturnto/wr4pt/models';

// Result of
// @campaign_influencers.as_json(include: {campaign: {include: :account}}, methods: [:unread_messages_count] ) }
export interface CampaignListInfluencer extends CampaignInfluencerRecord {
  campaign: CampaignRecord & {
    account: AccountRecord;
  };
  unread_messages_count: number;
}

const formatCampaignDate = (dateMoment: moment.Moment) => {
  const sinceNow = moment.duration(moment().diff(dateMoment));

  if (sinceNow.asDays() < 2) {
    return sinceNow.humanize();
  }

  return dateMoment.format('ll');
};

/**
 * Shows the list of campaign
 */
export const CampaignsList = ({ campaignInfluencers }: { campaignInfluencers: CampaignListInfluencer[]; }) => {
  const handleCampaignClick = (campaignInfluencerModel: CampaignListInfluencer) => {
    trackCampaignClick(campaignInfluencerModel.id, campaignInfluencerModel.campaign?.id);
  };

  return (
    <Flex $py={14} $fd="column" $alignItems="center" $gap={10} $align="stretch" $mt="var(--header-offset)">
      {campaignInfluencers?.length ? campaignInfluencers.map((c) => {
        return (
          <CampaignInfluencerItem key={c.id} href={`/campaign_influencers/${c.id}`} onClick={() => handleCampaignClick(c)}>
            <CampaignBrand>
              <BloxText $fs_md>{c.campaign.account?.business_name}</BloxText>
            </CampaignBrand>
            <CampaignContainer>
              <AccountAvatar unread_messages_count={c.unread_messages_count} logo={AccountMethods.getLogo(c.campaign.account) ?? ''} />

              <MiddlePart className={CampaignInfluencerMethods.isDeclined(c) ? 'declined' : ''}>
                <CampaignName>{CampaignMethods.getName(c.campaign)}</CampaignName>
                <BloxText $fs_sm $cname="slate4">
                  {CampaignMethods.getStartMoment(c.campaign)?.format('l')} - {CampaignMethods.getEndMoment(c.campaign)?.format('l')}
                </BloxText>

                {CampaignInfluencerMethods.isPending(c) && (
                  <BloxText $ws="nowrap" $fs_sm $bold $mt={5} $cname="yellow">
                    Awaiting response
                  </BloxText>
                )}
                {CampaignInfluencerMethods.isDeclined(c) && (
                  <BloxText $ws="nowrap" $fs_sm $bold $mt={5} $cname="red">
                    Declined
                  </BloxText>
                )}
              </MiddlePart>

              <DateContainer>
                <BloxText $fs_sm $cname="slate4" $align="flex-end" $ws="nowrap">
                  {formatCampaignDate(CampaignInfluencerMethods.getLastMessagedAtMoment(c))}
                </BloxText>
              </DateContainer>
            </CampaignContainer>
          </CampaignInfluencerItem>
        );
      }) : <BloxText $px={10} $mt={20} $fs_lg>You have not been invited to any campaigns yet.</BloxText>}
    </Flex>
  );
};


const CampaignInfluencerItem = styled.a`
  width: 100%;
  border-radius: 10px;
  max-width: var(--page_max_width);
  margin: 0 auto;
`;

const CampaignBrand = styled.div`
  background: var(--blox-slate1);
  color: black;
  padding: 6px 12px;
  border-radius: 10px 10px 0 0;
`;

const CampaignContainer = styled.div`
  padding: 20px;
  background: white;
  border-radius: 0 0 10px 10px;
  display: grid;
  grid-template-columns: min-content minmax(0px, 100%) min-content;
  gap: 15px;
`;

const MiddlePart = styled.div`
  &.declined {
    opacity: 0.3;
  }
`;

const DateContainer = styled.div`
  text-align: end;
  margin-top: 5px;
`;

const CampaignName = styled.div`
  color: black;
  font-family: var(--blox-font-family);
  font-weight: var(--blox-font-weight-700);
  font-size: var(--blox-font-size-lg);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${MiddlePart}.declined & {
    text-decoration: line-through;
    color: var(--blox-slate4);
  }
`;
