/*
 * Handles hiding/showing the drop down menu in the top actions view
 */
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['dropdownCheckbox'];

  // Hide dropdown when an option in the dropdown is clicked
  hideDropdown = () => {
    this.dropdownCheckboxTarget.checked = false;
  };
}
