/*
 * Handles signup validation when signing up as a new influencer (Page 1).
 */
import Dropzone from 'dropzone';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['submit', 'currentAvatar', 'requiredField', 'picBox', 'imagePreview', 'redirectUrl', 'email', 'deleteAvatar'];

  connect = () => {
    Dropzone.autoDiscover = false;
    this.createDropZone();
    // validate inputs on page load
    this.validateInputs();
    // this.validateEmail();
  };

  createDropZone = () => {
    const {
      redirectUrlTarget, picBoxTarget, imagePreviewTarget, currentAvatarTarget: { dataset: currentAvatar }, deleteAvatarTarget,
    } = this;
    const dropzoneOptions = {
      autoProcessQueue: false,
      uploadMultiple: false,
      parallelUploads: 1,
      maxFiles: 1,
      previewsContainer: '.image_preview',
      paramName: 'creator_profile[avatar]',
      addRemoveLinks: true,
      acceptedFiles: 'image/*',
      resizeHeight: 200,
      resizeWidth: 200,
      dictRemoveFile: 'Delete Photo',

      // if not specified, it will override 'Delete Photo' with 'Cancel Upload'
      dictCancelUpload: 'Delete Photo',
      init() {
        // Save reference to avatar file that is uploaded via dropzone
        let avatarFile;
        // Prepopulate avatar with avatar from server if it exists
        if (currentAvatar.currentAvatar) {
          const avatarUrl = currentAvatar.currentAvatar;
          avatarFile = { name: 'serverAvatar', size: 1 };
          const callback = null; // Optional callback when it's done
          const crossOrigin = null; // Added to the `img` tag for crossOrigin handling
          const resizeThumbnail = false; // Tells Dropzone whether it should resize the image first
          this.displayExistingFile(avatarFile, avatarUrl, callback, crossOrigin, resizeThumbnail);

          picBoxTarget.style.display = 'none';
          imagePreviewTarget.style.display = 'flex';
        }

        this.on('addedfile', (file) => {
          // Trick to remove the server loaded avatar if there is one
          if (avatarFile) {
            this.removeFile(avatarFile);
          }

          deleteAvatarTarget.value = '';
          avatarFile = file;

          picBoxTarget.style.display = 'none';
          imagePreviewTarget.style.display = 'flex';
        });

        this.on('removedfile', () => {
          deleteAvatarTarget.value = 'true';

          picBoxTarget.style.display = 'flex';
          imagePreviewTarget.style.display = 'none';
        });

        // First change the button to actually tell Dropzone to process the queue.
        this.element.querySelector('input[type=submit]').addEventListener('click', (ev) => {
          // Process files before sending the form if there are any files
          if (this.files.length > 0) {
            ev.preventDefault();
            ev.stopPropagation();
            this.processQueue();
          }
        });

        // Redirect to next page after the profile photo is uploaded (rails controller is unable to redirect via ajax)
        this.on('complete', () => {
          window.location.replace(redirectUrlTarget.value);
        });

        // If files are unable to be uploaded, notify the user
        this.on('errormultiple', () => {
          Toastr.error('Unable to process profile picture, please try again.');
        });
      },

    };
    this.dropzoneForm = new Dropzone(this.element, dropzoneOptions);
  };

  // Validate the inputs on the current page
  validateInputs = () => {
    let valid = true;
    this.requiredFieldTargets.forEach((requiredField) => {
      valid = valid && Boolean(requiredField.value);
    });
    this.submitTarget.disabled = !valid;
  };

  // Validate email
  validateEmail = () => {
    this.submitTarget.disabled = !this.emailTarget.value || !this.emailTarget.validity.valid;
  };
}
