import { PTTForm, BloxText, BloxTextArea } from '@pixleeturnto/wr4pt';
import type { HTMLInformEl } from 'informel';
import React from 'react';
import { isMobileDevice } from 'react/utils/isMobile';
import styled from 'styled-components';

interface SendActionBarProps {
  onSend: (param: { message: string; files: File[]; }) => void;
}

interface FormValues {
  message: string;
}

/**
 * The message textbox + file picker for attachment + send button
 */
export const SendActionBar = ({ onSend }: SendActionBarProps) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const textareaRef = React.useRef<HTMLTextAreaElement>(null);
  const formRef = React.useRef<HTMLInformEl>(null);

  // This used to "grow" the textarea when there are multiple lines
  const [textAreaRowsCount, setTextAreaRowsCount] = React.useState(1);
  const [files, setFiles] = React.useState<File[]>([]);


  const handleInput = ({ detail: { values } }: CustomEvent<{
    values: FormValues;
    changedField: string;
  }>) => {
    setTextAreaRowsCount(getMessageTextAreaRowCount(values.message));
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files ?? []) as File[];
    setFiles(currentFiles => [...currentFiles, ...files]);
  };

  const handleSubmit = ({ detail: { values } }: CustomEvent<{ values: FormValues; }>) => {
    onSend({
      message: values.message.trim(),
      files: files
    });
    // Clear the list of files shown after sending (the rest of the form is automatically cleared)
    setFiles([]);
    setTextAreaRowsCount(1);

    // After sending, keep focus on the text area, in case the user wants to send another message
    textareaRef.current?.focus();
  };

  const handleFileRemove = (fileIndex: number) => {
    setFiles(files.filter((_, i) => i !== fileIndex));
  };

  const handleValidation = ({ values }: { values: FormValues; }) => {
    const { message } = values;
    // Block sending when there is no message nor file
    if (message.length === 0 && files.length === 0) {
      return {
        message: 'Please enter a message or attach a file',
      };
    }

    return {};
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    // On desktop the enter key sends the message, enter+shift inserts a new line
    // On mobile, the enter key inserts a new line
    if (e.key === 'Enter') {
      const send = !isMobileDevice() && !e.shiftKey;

      if (send) {
        e.preventDefault();
        formRef.current?.requestSubmit();
      }
    }
  };

  return (
    <PTTForm
      ref={formRef}
      onInformInput={handleInput}
      onInformSubmit={handleSubmit}
      validationHandler={handleValidation}
      resetOnSubmit
    >
      <Attachments>
        {files.map((file, index) => (
          <FileContainer key={index}>
            <BloxText className="font-md-normal">{file.name}</BloxText>
            <button type="button" aria-label="Remove file" onClick={() => handleFileRemove(index)}>
              <i className="fa-light fa-xmark"></i>
            </button>
          </FileContainer>

        ))}
      </Attachments>
      <ActionBar>
        <BloxTextArea name="message" placeholder="message..." rows={textAreaRowsCount} ref={textareaRef}
          onKeyDown={handleKeyDown}

        />
        <input ref={fileInputRef} type="file" name="files" multiple hidden onChange={handleFileSelect} />
        <FileButton onClick={() => fileInputRef.current?.click()}>
          <i className="fa-light fa-paperclip"></i>
        </FileButton>
        <SubmitButton>
          <i className="fa-regular fa-paper-plane-top"></i>
        </SubmitButton>
      </ActionBar>
    </PTTForm>
  );
};

/**
 * Returns the number of rows the textarea should have based on content:
 * - 1 row per line (separated by \n or `\r\n`)
 * - also split lines greater than 70 characters since the textarea will wrap them
 */
const getMessageTextAreaRowCount = (message: string) => {
  const lines = message.split(/\r\n|\r|\n/);

  // The textarea also auto-wraps long lines: 
  // we're counting lines with more than 70 characters as 2 lines
  const linesWithAutoWrap = lines.reduce((count, line) => {
    const lineLength = line.length || 1;
    return count + Math.ceil(lineLength / 70);
  }, 0);

  return Math.min(4, linesWithAutoWrap);
};


const ActionBar = styled.div`
  background: var(--blox-slate1);
  border-radius: 0 0 5px 5px;
  display: flex;
  align-items: center;
  padding-right: 5px;
  gap: 5px;

  textarea {
    width: initial;
    border: none;
    color: initial;
    font-family: var(--blox-font-family);
    font-size: var(--blox-font-size);
    font-weight: 300;
    background: transparent;
    flex: 1;
  }
`;

const ActionButton = styled.button`
  width: 39px;
  height: 39px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const SubmitButton = styled(ActionButton).attrs({
  type: 'submit',
  'aria-label': 'Send',
})`
  width: 39px;
  height: 39px;
  background: var(--blox-purple--active);
  padding: 0;
  margin: 0;
  color: white;
  font-size: 14px;
`;

const FileButton = styled(ActionButton).attrs({
  type: 'button',
  'aria-label': 'Attach file',
})`
  background: white;
  color: black;
`;

const FileContainer = styled.div`
  background: var(--blox-slate1);
  padding: 5px;
  border-bottom: 1px solid var(--blox-slate1a);
  display: flex;
  justify-content: space-between;

  button {
    width: auto;
    height: auto;
    background: none;
    padding: 0;
    margin: 0;
  }
`;

const Attachments = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;
