import React from 'react';
import styled from 'styled-components';
import { Text, Flex } from '@pixleeturnto/wr4pt';

import { CampaignLinkItem } from './CampaignLinkItem';
import type { FetchCampaignInfluencerResult } from 'apis/campaign_influencers';

/** 
 * Campaign Links section in the campaign details page
 */
export const CampaignLinks = ({ campaignLinks }: { campaignLinks: FetchCampaignInfluencerResult['campaign_influencer_links']; }) => {

  return (
    <Flex $fd="column">
      {campaignLinks.map(link => <CampaignLinkItem key={link.id} campaignLink={link} />)}
      {campaignLinks.length === 0 && <NoLinksText>There are no links associated with this campagin.</NoLinksText>}
    </Flex>
  );
};

const NoLinksText = styled(Text)`
  font-size: 16px;
  font-weight: 700;
  color: var(--blox-slate5);
  width: 100%;
  text-align: center;
  padding-top: 20px;
`;
