import { StatusContextProvider } from '@pixleeturnto/wr4pt';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();

/**
 * Component wrapper that adds the react-query client component + blox status. Used when mounting a react component
 * inside a rails view.
 * @param {import('react').Component} Component
 */
export const withWrappers = (Component) => {
  const wrapped = (props) => {
    return <QueryClientProvider client={queryClient}>
      <StatusContextProvider>
        <Component {...props} />
      </StatusContextProvider>
    </QueryClientProvider>;
  };
  wrapped.displayName = Component.displayName || Component.name || 'Component';

  return wrapped;
};
