/*
 * Handles initializing dropzone (https://www.dropzonejs.com/) for file uploading
 */
import Dropzone from 'dropzone';
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input'];

  connect = () => {
    Dropzone.autoDiscover = false;
    this.createDropZone();
  };

  createDropZone = () => {
    const dropzoneOptions = {
      autoProcessQueue: false,
      uploadMultiple: true,
      parallelUploads: 10,
      maxFiles: 10,
      previewsContainer: '.dropzone-previews',
      clickable: ['#file-upload-button'],
      paramName: 'campaign_message[attachments]',
      addRemoveLinks: true,
      init() {
        const myDropzone = this;
        // First change the button to actually tell Dropzone to process the queue.
        this.element.querySelector('button[type=submit]').addEventListener('click', (e) => {
          // Process files before sending the form if there are any files
          if (myDropzone.files.length > 0) {
            e.preventDefault();
            e.stopPropagation();
            myDropzone.processQueue();
          }
        });
        // If successful refresh the page to show new chat messages
        this.on('successmultiple', () => {
          Turbolinks.visit(window.location, { action: 'replace' });
        });
        // If files are unable to be uploaded, notify the user
        this.on('errormultiple', () => {
          Toastr.error('Unable to process attachments, please try again.');
        });
      },

    };
    this.dropzoneForm = new Dropzone(this.element, dropzoneOptions);
  };
}
