/*
 * Handles signup validation when signing up as a new influencer.
 * Extends from stimulus-validation (https://github.com/jwald1/stimulus-validation) for validation helpers.
 */
import { ValidationController } from 'stimulus-validation';

export default class extends ValidationController {
  static targets = ['email', 'emailCheckbox', 'tosCheckbox', 'submitButton'];

  static validators = { boxChecked: { attributes: ['emailCheckbox'] } };

  static rules = {
    email: {
      presence: {
        allowEmpty: false,
      },
      email: true,
    },
  };

  // After validation, add/remove appropriate errors
  afterValidate = ({ el, attr }) => {
    this.validateForm();
    el.classList.remove('error');
    el.nextElementSibling.style.visibility = 'hidden';
    if (this.errors.get(attr)) {
      el.classList.add('error');
      el.nextElementSibling.style.visibility = 'visible';
    }
  };

  // Remove errors from form
  removeError = (el) => {
    el.currentTarget.classList.remove('error');
    el.currentTarget.nextElementSibling.style.visibility = 'hidden';
    if (this.emailCheckboxTarget.checked && this.tosCheckboxTarget.checked) {
      this.submitButtonTarget.disabled = false;
    }
  };

  // Enable/Disable submit button
  validateForm = () => {
    if (this.emailCheckboxTarget.checked && this.tosCheckboxTarget.checked && !this.errors.hasAny()) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  };
}
