import { Box, BloxText, Text, BloxButton } from '@pixleeturnto/wr4pt';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useCampaignProducts } from 'react/apis/campaign_products';
import { Loader } from 'react/components/Loader';
import styled from 'styled-components';
import { ProductDetails } from './ProductDetails';
import type { FetchCampaignInfluencerResult } from 'apis/campaign_influencers';

/**
 * This screen allows creators to pick their products and variants
 */
export const SelectCampaignProducts = ({ campaignInfluencer }: { campaignInfluencer: FetchCampaignInfluencerResult; }) => {
  const { data: products, isLoading, isError } = useCampaignProducts({ campaignId: campaignInfluencer.campaign?.id });

  // map: productId => variantId
  const [selectedProducts, setSelectedProducts] = React.useState<Record<string, string>>(getProductsFromSessionStorage(campaignInfluencer.id) ?? {});

  const navigate = useNavigate();

  if (isError) {
    return <BloxText $mt={20} $fs_md $cname="slate7" $textAlign="center">Something went wrong, please try again later.</BloxText>;
  }

  const handleConfirm = () => {
    setProductsInSessionStorage(campaignInfluencer.id, selectedProducts);
    navigate('./recap');
  };

  const handleSelectedChange = (productId: number, variantId: string | null, selected: boolean) => {
    if (selected) {
      setSelectedProducts(selectedProducts => ({
        ...selectedProducts,
        [productId]: variantId
      }));
    } else {
      setSelectedProducts(selectedProducts => {
        const newSelectedProducts = { ...selectedProducts };
        delete newSelectedProducts[productId];
        return newSelectedProducts;
      });
    }
  };

  const maxProducts = campaignInfluencer.campaign?.max_products;
  const notes = campaignInfluencer.campaign?.products_notes;

  const selectedProductsCount = Object.keys(selectedProducts).length;
  const capacityReached = !!maxProducts && selectedProductsCount >= maxProducts;
  const tooManyProducts = !!maxProducts && selectedProductsCount > maxProducts;

  const saveButtonDisabled = selectedProductsCount === 0 ||
    tooManyProducts ||
    // If some selected products don't have a variant yet (= not all options have been selected)
    Object.keys(selectedProducts).some(productId => !selectedProducts[productId]);

  return (
    <>
      {selectedProductsCount > 0 && <TopBanner>{selectedProductsCount}{maxProducts ? ` of ${maxProducts}` : ''} selected</TopBanner>}

      <Box $p={20}>

        <BloxText className="font-lg-heading">Select your free products!</BloxText>
        <Text $fs_sm $cname="slate5" $mt={25}>Select the free products you would like to use in your post.{maxProducts ? ` You can select up to ${maxProducts} products.` : ''}</Text>
        {!!notes && (
          <NotesContainer>
            <Text $fs_sm $bold $cname="slate5">Notes:</Text>
            <Text $fs_sm $cname="slate5" $mt={5}>{notes}</Text>
          </NotesContainer>
        )}
        {isLoading ? <Loader $mt={20}>Loading...</Loader> : products?.map(p => {
          const selected = p.id in selectedProducts;
          const selectedVariantId = selectedProducts[p.id];
          return <ProductDetails
            key={p.id}
            product={p}
            disabled={capacityReached && !selected}
            selected={selected}
            selectedVariantId={selectedVariantId}
            onSelectedToggle={handleSelectedChange}
          />;
        })}
        {!isLoading && (
          <BloxButton $mt={20} $w="100%" disabled={saveButtonDisabled} onClick={handleConfirm}>Confirm your products</BloxButton>
        )}
      </Box>
    </>
  );
};

const getSessionStorageKey = (campaignInfluencerId: number) => `campaign_products_${campaignInfluencerId}`;
export const setProductsInSessionStorage = (campaignInfluencerId: number, products: Record<string, string>) => {
  sessionStorage.setItem(getSessionStorageKey(campaignInfluencerId), JSON.stringify({ selectedProducts: products }));
};

export const getProductsFromSessionStorage = (campaignInfluencerId: number): Record<string, string> => {
  const data = sessionStorage.getItem(getSessionStorageKey(campaignInfluencerId));
  try {
    const parsedData = data ? JSON.parse(data) : {};
    return parsedData.selectedProducts ?? {};
  } catch {
    return {};
  }
};

const NotesContainer = styled.div`
  border: 1px solid var(--blox-slate1c);
  border-radius: 5px;
  padding: 20px;
  margin-top: 25px;
  white-space: break-spaces;
  overflow-wrap: break-word;
`;


const TopBanner = styled(BloxText)`
  background-color: var(--blox-slate6);
  text-align: center;
  padding: 10px;
  font-size: var(--blox-font-size-md);
  color: white;
  position: absolute;
  width: 100%;
  z-index: 1;
`;
