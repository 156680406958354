/*
 * Handles signup validation when signing up as a new influencer (Page 1).
 */
import { Controller } from 'stimulus';

class ProfileController extends Controller {
  static targets = ['submit', 'requiredField', 'redirectUrl', 'email'];

  // validate inputs on page load
  connect = () => this.validateInputs();

  // Validate the inputs on the current page
  validateInputs = () => {
    const invalid = Array
      .from(this.requiredFieldTargets)
      .some((requiredField) => !requiredField.value);
    this.submitTarget.disabled = invalid;
  };

  // Validate email
  validateEmail = () => {
    this.submitTarget.disabled = !this.emailTarget.value || !this.emailTarget.validity.valid;
  };
}

export default ProfileController;
