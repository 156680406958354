import { Controller } from 'stimulus';
import { analyticsCategoriesError } from '../analytics_controller';

export class InterestsController extends Controller {
  static targets = ['submit', 'checkbox'];

  static INTEREST_LIMIT = 5;

  #showErrorToast = () => Toastr.error(`You can select up to ${InterestsController.INTEREST_LIMIT} interests.`);

  #checkedCount = () => Array.from(this.checkboxTargets).reduce((count, el) => count + el.checked, 0);

  onInterestSelected = (e) => {
    const checkedCount = this.#checkedCount();

    if (checkedCount > InterestsController.INTEREST_LIMIT) {
      e.currentTarget.checked = false; // revert the chekced
      // Track error to segment/keen
      analyticsCategoriesError();
      // Show toast
      this.#showErrorToast();
    }
  };
}

export default InterestsController;
