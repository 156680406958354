import React from 'react';
import styled from 'styled-components';
import { BloxText } from '@pixleeturnto/wr4pt';
import type { ShippingAddress } from 'apis/account';

export const CampaignInfluencerShippingAddress = ({ shippingAddress }: { shippingAddress: ShippingAddress }) => {
  return <>
    <BloxText $cname="slate4" $fs_md $mb={20} >
      Your order will be shipped to: <BloxText as="span" $cname="slate7">{shippingAddress.first_name} {shippingAddress.last_name}</BloxText>
    </BloxText>
    <AddressContainer>
      <AddressLine>{shippingAddress.address_line_1}</AddressLine>
      <AddressLine>{shippingAddress.address_line_2}</AddressLine>
      <AddressLine>{shippingAddress.city}, {shippingAddress.state} {shippingAddress.zip}</AddressLine>
      <AddressLine>{shippingAddress.country}</AddressLine>
    </AddressContainer>
  </>;
};

const AddressContainer = styled.div`
  padding: 20px;
  border: 1px solid black;
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const AddressLine = styled(BloxText)`
  color: var(--blox-slate5);
  font-size: var(--blox-font-size-md);
  text-transform: uppercase;
`;
