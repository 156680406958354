import { useMutation, useQuery, useQueryClient } from 'react-query';
import { trackCampaignAccept, trackCampaignDecline } from 'react/utils/analytics';
import type { FetchCampaignInfluencerResult, RespondToCampaignParams } from 'apis';
import { respondToCampaign, fetchCampaignInfluencer } from 'apis';

export const getCampaignInfluencersQueryKey = (campaignInfluencerId: number) => ['campaignInfluencers', campaignInfluencerId];
/**
 * Hook for responding (accepting or declining) a campaign
 */
export const useRespondToCampaign = ({ campaignInfluencerId, campaignId }: { campaignInfluencerId: number, campaignId: number; }) => {
  const queryClient = useQueryClient();

  return useMutation<unknown, unknown, Omit<RespondToCampaignParams, 'campaignInfluencerId'>>(({
    accept,
    authenticityToken // the authenticity token is given by rails
  }) => respondToCampaign({ campaignInfluencerId, accept, authenticityToken }), {
    onMutate: ({ accept }) => {
      if (accept) {
        trackCampaignAccept(campaignInfluencerId, campaignId);
      } else {
        trackCampaignDecline(campaignInfluencerId, campaignId);
      }
    },
    onSuccess: (_result, { accept }) => {
      // Update the cache
      queryClient.setQueryData<FetchCampaignInfluencerResult>(getCampaignInfluencersQueryKey(campaignInfluencerId), (currentData) => {
        if (!currentData) {
          // This is a react-query type issue that was fixed in v4:  currentData is `TData | undefined` while the expected return value is `TData`.
          // If currentData is undefined, we can't invent some data to return.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          return currentData!;
        }
        return {
          ...currentData,
          status: accept ? 'accepted' : 'declined'
        };
      });
    }
  });
};


/**
 * Hook for fetching a single campaign influencer
 */
export const useCampaignInfluencer = ({ campaignInfluencerId }: { campaignInfluencerId: number; }, options: {
  initialData?: FetchCampaignInfluencerResult;
}) => {
  return useQuery(getCampaignInfluencersQueryKey(campaignInfluencerId), async () => {
    const { data } = await fetchCampaignInfluencer(campaignInfluencerId);

    return data;
  }, options);
};
