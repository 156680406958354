/*
 * Localizes time for the user from timestamps stored in the database
 */
import { Controller } from 'stimulus';
import moment from 'moment';

// Controller to localize time in views
export default class extends Controller {
  connect = () => {
    const format = this.data.get('format');
    if (format === 'campaign') {
      this.formatUtcCampaign();
    } else {
      this.formatUtcMessage();
    }
  };

  // Format time/date in the chat screen
  formatUtcMessage = () => {
    const timeStamp = this.data.get('utc');
    const localTimeStamp = moment.utc(new Date(timeStamp.replace(/-/g, '/'))).local().calendar();
    this.element.textContent = localTimeStamp;
  };

  // Format time/date in the campaign lists screen
  formatUtcCampaign = () => {
    const timeStamp = this.data.get('utc');
    if (!timeStamp) {
      return;
    }
    const today = moment().endOf('day').local();
    const yesterday = moment().subtract(1, 'day').endOf('day').local();
    const twoDaysAgo = moment().subtract(2, 'day').endOf('day').local();
    const localTimeStamp = moment.utc(new Date(timeStamp.replace(/-/g, '/'))).local();
    if (localTimeStamp <= today && localTimeStamp >= yesterday) {
      this.element.textContent = 'Today';
    } else if (localTimeStamp < yesterday && localTimeStamp >= twoDaysAgo) {
      this.element.textContent = 'Yesterday';
    } else {
      this.element.textContent = localTimeStamp.format('D MMM YY');
    }
  };
}
