import { segmentTrack } from 'analytics';


export const trackCampaignClick = (campaignInfluencerId, campaignId) => {
  const props = {
    campaign_influencer: {
      id: campaignInfluencerId,
    },
    campaign: {
      id: campaignId,
    },
  };
  segmentTrack('Campaign Click', props);
};

export const trackCampaignAccept = (campaignInfluencerId, campaignId) => {
  const props = {
    campaign_influencer: {
      id: campaignInfluencerId,
    },
    campaign: {
      id: campaignId,
    },
  };
  segmentTrack('Campaign Accept', props);
};

export const trackCampaignDecline = (campaignInfluencerId, campaignId) => {
  const props = {
    campaign_influencer: {
      id: campaignInfluencerId,
    },
    campaign: {
      id: campaignId,
    },
  };
  segmentTrack('Campaign Decline', props);
};

export const trackMenuNavigate = (menuItemName) => {
  const props = {
    menu_item: {
      name: menuItemName,
    },
  };
  segmentTrack('Menu Navigate', props);
};


export const trackCampaignMessageSent = (campaignInfluencerId, campaignId) => {
  const props = {
    campaign_influencer: {
      id: campaignInfluencerId,
    },
    campaign: {
      id: campaignId,
    },
  };
  segmentTrack('Campaign Message Sent', props);
};

export const trackCampaignMessageFileClick = (campaignInfluencerId, campaignId) => {
  const props = {
    campaign_influencer: {
      id: campaignInfluencerId,
    },
    campaign: {
      id: campaignId,
    },
  };
  segmentTrack('Campaign Message File Click', props);
};
