import { pttFetch } from '@pixleeturnto/wr4pt';
import { ProductAsJson } from 'react/types/product';
import {
  AccountRecord,
  CampaignAttachmentRecord,
  CampaignGoalRecord,
  CampaignInfluencerLinkRecord,
  CampaignInfluencerRecord,
  CampaignRecord,
  CampaignProductRecord,
} from '@pixleeturnto/wr4pt/dist/models';

export interface RespondToCampaignParams {
  campaignInfluencerId: number;
  accept: boolean;
  authenticityToken: string;
}

// the authenticity token is given by rails
export const respondToCampaign = async ({ campaignInfluencerId, accept, authenticityToken }: RespondToCampaignParams) => (
  pttFetch<void>(`/campaign_influencers/${campaignInfluencerId}`, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ status: accept ? 'accepted' : 'declined', authenticity_token: authenticityToken }),
  })
);

// Result of
// @campaign_influencer.as_json(include: [
//     campaign: {
//         include: [: account, : campaign_goals, : products,
//             { campaign_attachments: { methods: [: file_url] } }]
//     },
//     campaign_influencer_links: {
//         include: { campaign_product: { include: [: product] } }, methods: : link
//     }
// ])
export interface FetchCampaignInfluencerResult extends CampaignInfluencerRecord {
  campaign: CampaignRecord & {
    campaign_attachments: Array<CampaignAttachmentRecord & { file_url: string | null; }>;
    campaign_goals: CampaignGoalRecord[];
    account: AccountRecord;
    products: ProductAsJson[];
  };
  campaign_influencer_links: Array<CampaignInfluencerLinkRecord & {
    campaign_product?: CampaignProductRecord & {
      product: ProductAsJson;
    };
    link: string;
  }>;
  hasChosenProducts: boolean;
}

export const fetchCampaignInfluencer = async (campaignInfluencerId: number) => (pttFetch<FetchCampaignInfluencerResult>(`/campaign_influencers/${campaignInfluencerId}`, {
  method: 'GET',
  headers: {
    Accept: 'application/json',
  },
}));
