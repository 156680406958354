import { Controller } from 'stimulus';

/**
* Properties used for setting the contents of the dialog. These properties must match
* one of the AccountController.targets that begins with "dialog".
* @typedef DialogContents
* @type {{cancelButton: string, title: string, confirmButton: string, content: string}}
*/

/**
 * The contents of the dialog that pops up when a user initiates an action to delete their account.
 * @type {DialogContents}
 */
const deleteAccountDialogContents = {
  title: 'Delete Account?',
  content: 'Deleting your account permanently removes all of your settings and historical data.',
  confirmButton: 'YES, DELETE',
  cancelButton: 'NO, KEEP',
};

class ReactivationController extends Controller {
  static targets = [
    'deletionLink',
    'deletedDialog',
    'dialogTitle',
    'dialogContent',
    'dialogConfirmButton',
    'dialogCancelButton',
    'dialogWrapper',
  ];

  /**
   * Applies the given contents to the dialog popup.
   * @param {DialogContents} contents
   */
  #applyDialogContents = (contents = {}) => Object
    .entries(contents)
    .forEach(([key, value]) => {
      const keyWithUppercaseFirstLetter = key.charAt(0).toUpperCase() + key.slice(1);
      this[`dialog${keyWithUppercaseFirstLetter}Target`].innerText = value;
    });

  #isDeleteAccountDialog = () => this.dialogTitleTarget.innerText === deleteAccountDialogContents.title;

  #deleteAccountDialog = () => this.#applyDialogContents(deleteAccountDialogContents);

  /**
   * This is for closing the dialogs that are used as warning prompts
   */
  connect = () => {
    this.dialogWrapperTarget.addEventListener('transitionend', () => {
      this.dialogWrapperTarget.classList.add('hidden');
    });
  };

  #closeDialog = () => {
    this.dialogWrapperTarget.classList.add('no_opacity');
  };

  #openDialog = () => {
    this.dialogWrapperTarget.classList.remove('hidden');
    this.dialogWrapperTarget.classList.remove('no_opacity');
  };

  onDeleteAccountClick = (e) => {
    e.preventDefault();
    this.#deleteAccountDialog();
    this.#openDialog();
  };

  onDialogCancel = () => {
    this.#closeDialog();
  };

  onDialogOpen = () => {
    this.#openDialog();
  };

  onDialogConfirm = () => {
    if (this.#isDeleteAccountDialog()) {
      this.deletionLinkTarget.click();
    }
  };

  onDeletedAccountDialogCloseClick = () => {
    this.deletedDialogTarget.classList.add('hidden');
    window.Toastr.warning('Your account is deleted.');
  };
}

export default ReactivationController;
