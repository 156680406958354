import React, { useState } from 'react';
import styled from 'styled-components';
import { formatCompact } from 'react/utils/numbers';

export interface AccountAvatarProps {
  logo: string;
  unread_messages_count?: number;
}

export const AccountAvatar = ({ logo, unread_messages_count }: AccountAvatarProps) => {
  const [showAvatar, setShowAvatar] = useState(!!logo);
  const handleImgError = () => setShowAvatar(false);
  return (
    <AvatarContainer>
      <UserIcon />
      {showAvatar && (
        <AvatarImg src={logo} alt="logo" onError={handleImgError} />
      )}
      {!!unread_messages_count && (
        <CampaignBadge>
          {formatCompact(unread_messages_count)}
        </CampaignBadge>
      )}
    </AvatarContainer>
  );
};

const UserIcon = styled.i.attrs({
  className: 'fas fa-user-circle',
})`
    color: var(--blox-slate1b);
    font-size: 24px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
const AvatarContainer = styled.div`
  border-radius: 50%;
  width: 54px;
  height: 54px;
  background: var(--blox-slate1);
  position: relative;
`;

const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  border-radius: 50%;
`;

const CampaignBadge = styled.div`
  background: white;

  position: absolute;
  border-radius: 50%;
  background: var(--blox-orange);
  border: 3px solid white;
  right: 13%;
  top: 13%;
  transform: translate(50%, -50%);

  min-width: 28px;
  height: 28px;
  line-height: 21px;
  text-align: center;

  font-family: var(--blox-font-family);
  font-size: 12px;
  font-weight: 700;
  color: white;
`;
