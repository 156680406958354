/*
 * Handles signup validation when signing up as a new influencer (Page 2).
 */
import { Controller } from 'stimulus';
import csc from 'country-state-city';
import { find } from 'lodash';
import parsePhoneNumber, { getExampleNumber } from 'libphonenumber-js';
import examples from 'libphonenumber-js/examples.mobile.json';

export default class AdditionalInformationController extends Controller {
  static targets = ['country', 'state', 'city', 'telephone', 'phoneErrorMessage'];

  connect = () => {
    // Keep track of country/state/city objects for easier lookups
    // Find country id from reverse lookup of country name
    const selectedCountry = this.countryTarget.options[this.countryTarget.selectedIndex].value;
    this.selectedCountryObj = find(this.getCountries(), ['name', selectedCountry]) || {};
    // Find state id from reverse lookup of country name
    const selectedState = this.stateTarget.options[this.stateTarget.selectedIndex].value;
    this.selectedStateObj = find(this.getStates(this.selectedCountryObj.id), ['name', selectedState]) || {};
    // Find city id from reverse lookup of country name
    const selectedCity = this.cityTarget.options[this.cityTarget.selectedIndex].value;
    this.selectedCityObj = find(this.getCities(this.selectedStateObj.id), ['name', selectedCity]) || {};

    // On initalize, pre-populate fields from database if any information has been previously saved
    this.initCountryDropdown();
  };

  /* *************************************************************
   *                     UTILITY METHODS                         *
   ************************************************************* */

  // Function to get all the countries from country-stake-city library
  getCountries = () => csc.getAllCountries();

  // Function to get all the countries from country-stake-city library via countryId
  getStates = (countryId) => csc.getStatesOfCountry(countryId);

  // Function to get all the cities from country-stake-city library via stateId
  getCities = (stateId) => csc.getCitiesOfState(stateId);

  // "Reset" options in dropdown to be replaced by corresponding selected state/city/country
  removeOptions = (target) => {
    const selector = this[`${target}Target`];
    // Remove all options except default "Please Select..." or "-" option which is always first
    while (selector.options.length > 1) {
      selector.remove(1);
    }
  };

  /* *************************************************************
   *                     COUNTRY METHODS                         *
   ************************************************************* */

  // Init dountry dropdown
  initCountryDropdown = () => {
    // Remove all options before re-populating dropdown
    this.removeOptions('country');
    // Add each country to the dropdown
    this.getCountries().forEach((country) => {
      const option = new Option(country.name, country.name);
      this.countryTarget.add(option);
    });
    // Set the selected option to previously selected value if there is one
    const { selectedCountryObj: { name }, countryTarget: { value } } = this;
    this.countryTarget.value = name || value;
    // If country has been selected, enable dropdown options for state
    this.stateTarget.disabled = !name;
    if (name) {
      const placeHolderPhone = getExampleNumber(this.selectedCountryObj.sortname, examples) || getExampleNumber('US', examples); // for non-supported countries in the library, default would be US
      this.telephoneTarget.placeholder = `Ex. ${placeHolderPhone.formatInternational()}`;
      this.onTelephoneInput();
    }
    // Init state dropdown for the corresponding selected country
    this.initStateDropdown();
  };

  // Handle selecting a country from dropdown
  onSelectCountry = (option) => {
    this.selectedCountryObj = find(this.getCountries(), ['name', option.target.value]) || {};
    // clear state and city since we are changing countrys
    this.selectedStateObj = {};
    this.selectedCityObj = {};
    // Now that a country was selected, init new country -> state -> city dropdowns
    this.initCountryDropdown();
  };

  /* *************************************************************
   *                      STATE METHODS                          *
   ************************************************************* */

  // Init state dropdown
  initStateDropdown = () => {
    // Remove all options before re-populating dropdown
    this.removeOptions('state');
    // Add each state to the options dropdown
    this.getStates(this.selectedCountryObj.id).forEach((state) => {
      const option = new Option(state.name, state.name);
      this.stateTarget.add(option);
    });
    // Set the selected option to previously selected value if there is one
    const { selectedStateObj: { name }, stateTarget: { value } } = this;
    this.stateTarget.value = name || value;
    // If state has been selected, enable dropdown options for city
    this.cityTarget.disabled = !name;
    // Init city dropdown for the corresponding selected state
    this.initCityDropdown();
  };

  // Handle selecting a state from dropdown
  onSelectState = (option) => {
    this.selectedStateObj = find(this.getStates(this.selectedCountryObj.id), ['name', option.target.value]) || {};
    // clear city since we are changing state
    this.selectedCityObj = {};
    // Now that a country was selected, init new state -> city dropdowns
    this.initStateDropdown();
  };

  /* *************************************************************
   *                      CITY METHODS                           *
   ************************************************************* */

  // Init City dropdown
  initCityDropdown = () => {
    // remove all options before re-populating dropdown
    this.removeOptions('city');

    const cities = this.getCities(this.selectedStateObj.id);
    const noCitiesFound = cities.length === 0;

    // Add each city to the options dropdown for country
    cities.forEach((city) => {
      this.cityTarget.add(new Option(city.name, city.name));
    });

    // Set the selected option to previously selected value if there is one
    const { selectedCityObj: { name }, cityTarget: { value } } = this;
    this.cityTarget.value = name || value;

    // disable dropdown if no cities was found
    this.cityTarget.disabled = noCitiesFound;
  };

  // Handle selecting a city from dropdown
  onSelectCity = (option) => {
    this.selectedCityObj = find(this.getCities(this.selectedStateObj.id), ['name', option.target.value]) || {};
  };
  /* *************************************************************
   *                      TELEPHONE METHODS                      *
   ************************************************************* */

  onTelephoneInput = () => {
    const telephoneValue = this.telephoneTarget.value;
    const countryCode = this.selectedCountryObj?.sortname || 'US'; // if a country is not selected yet
    let phoneNumber = parsePhoneNumber(telephoneValue, countryCode);

    if (!phoneNumber && telephoneValue) { // If a country is not supported by library. e.g. Antarctica
      phoneNumber = parsePhoneNumber(telephoneValue, 'US');
    }

    if (phoneNumber?.isValid() || !telephoneValue) { // phoneNumber could be undefined if there is no phone entered
      this.telephoneTarget.classList.remove('error');
      this.phoneErrorMessageTarget.innerText = '';
    } else {
      this.telephoneTarget.classList.add('error');
      const placeHolderExample = this.telephoneTarget.placeholder;
      this.phoneErrorMessageTarget.innerText = `Please enter a valid phone (${placeHolderExample})`;
    }
  };
}
