/**
 * analytics.js
 *
 * Functions for segement/keen analytics. Segement is initalized with the snippet in
 * app/views/layouts/_segment.html.erb when in production.
 */

// Builds properties to send to keen.
// by default it includes the creator_profile if it is available via gon.
// Appends any additional properties passed in
const buildProperties = (additionalProperties = {}) => ({
  ...window?.gon?.creator_profile && { creator_profile: { ...window.gon.creator_profile } },
  ...additionalProperties,
});

// Track method for segment
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#track
export const segmentTrack = (eventName, properties) => {
  if (!window.analytics) {
    return;
  }
  const props = buildProperties(properties);
  window.analytics.track(eventName, props);
};

// Page method for segment
// https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page
export const segmentPage = (pageCategory, pageName, properties) => {
  if (!window.analytics) {
    return;
  }
  const props = buildProperties(properties);
  window.analytics.page(pageCategory, pageName, props);
};

// Function to track all page views to keen.
// Listens to when turbolinks loads a page and sends loaded page info to keen
export const segementTrackAllPageViews = () => {
  document.addEventListener('turbolinks:load', () => {
    segmentPage();
  });
};
